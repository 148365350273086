import "./global.scss"
import "./theme.css"
import "./whitelabeltheme.css"
import "react-toastify/dist/ReactToastify.css"
import "react-input-range/lib/css/index.css"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

import axios from "axios"
import { ConnectedRouter } from "connected-react-router"
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { toast, ToastContainer } from "react-toastify"

import App from "./js/components/App"
import ScrollToTop from "./js/components/atoms/ScrollToTop"
import InfoModal from "./js/components/organisms/InfoModal"
import LoadingPage from "./js/components/pages/LoadingPage"
import { history, store } from "./js/redux/store"
import { setTheme } from "./js/utils/app-utils"
import userBehaviourUtils from "./js/utils/userBehaviour-utils"

axios.defaults.withCredentials = true
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest"

setTheme()

userBehaviourUtils.initializeEventListeners()

ReactDOM.render(
  <Suspense fallback={<LoadingPage />}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <App />
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
          <InfoModal />
          <div id="portal" />
        </ScrollToTop>
      </ConnectedRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root"),
)
