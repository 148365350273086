import "./TimePicker.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { useOnClickOutside } from "../../../utils/hook"

const TimePicker = ({ value, onChange, gray, disabled }) => {
  const hours = []
  for (let i = 0; i <= 23; i++) {
    let newValue = i
    if (newValue < 10) {
      newValue = `0${newValue}`
    }
    hours.push(newValue)
  }

  const minutes = []
  for (let i = 0; i <= 59; i++) {
    let newValue = i
    if (newValue < 10) {
      newValue = `0${newValue}`
    }
    minutes.push(newValue)
  }
  const timePickerClass = classNames("time-picker-container", {
    "time-picker-gray": gray,
  })

  const [pickerOpen, setPickerOpen] = useState(false)
  const [selectedHour, setSelectedHour] = useState("")
  const [selectedMinute, setSelectedMinute] = useState("")

  useEffect(() => {
    const splitValue = value.split(":")
    setSelectedHour(splitValue[0])
    setSelectedMinute(splitValue[1])
  }, [value])

  const selectHour = hour => {
    setSelectedHour(hour)
    let newMinutes = selectedMinute
    if (!selectedMinute) {
      newMinutes = "00"
      setSelectedMinute("00")
    }

    onChange(`${hour}:${newMinutes}`)
  }

  const selectMinute = minute => {
    setSelectedMinute(minute)
    let newHours = selectedHour
    if (!selectedHour) {
      newHours = "00"
      setSelectedHour("00")
    }

    onChange(`${newHours}:${minute}`)
  }

  const timePickerRef = useRef(null)

  const togglePicker = () => {
    if (!disabled) setPickerOpen(!pickerOpen)
  }

  useOnClickOutside(timePickerRef, () => setPickerOpen(false))

  return (
    <div className={timePickerClass} onClick={togglePicker} ref={timePickerRef}>
      <span className="time-picker-text">
        {(selectedHour || selectedMinute) && `${selectedHour} : ${selectedMinute}`}
      </span>
      {pickerOpen && (
        <div className="time-picker-wrapper">
          <div className="time-picker-side time-picker-hours">
            {hours.map(hour => (
              <div
                className="time-picker-select"
                onClick={() => {
                  selectHour(hour)
                }}
              >
                {hour}
              </div>
            ))}
          </div>
          <div className="time-picker-side time-picker-minutes">
            {minutes.map(minute => (
              <div
                className="time-picker-select"
                onClick={() => {
                  selectMinute(minute)
                }}
              >
                {minute}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

TimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  gray: PropTypes.bool,
  disabled: PropTypes.bool,
}

TimePicker.defaultProps = { value: "", onChange: () => {}, gray: false, disabled: false }

export default TimePicker
