import "./LoadingPage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import animationLine from "../../../constants/animation/loading-line.json"
import { getLogo } from "../../../utils/app-utils"
import { useConfig } from "../../../utils/hook"
import LottieAnimation from "../../atoms/LottieAnimation"

const LoadingPage = ({ style }) => {
  const config = useConfig()

  const loadingPageWrapper = classNames("loading-page-wrapper")
  return (
    <div className={loadingPageWrapper} style={style}>
      <div className="loading-page-inner-wrapper">
        <img
          src={getLogo(config)}
          alt="logo"
          width={config.REACT_APP_LOGO_IMAGE_WIDTH}
          height={config.REACT_APP_LOGO_IMAGE_HEIGHT}
        />
        <LottieAnimation animation={animationLine} width={400} height={100} />
      </div>
    </div>
  )
}
LoadingPage.propTypes = {
  style: PropTypes.instanceOf(Object),
}
LoadingPage.defaultProps = {
  style: {},
}

export default React.memo(LoadingPage)
