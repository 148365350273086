import PropTypes from "prop-types"
import React from "react"
import Lottie from "react-lottie"

const LottieAnimation = ({ animation, width, height }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        style={{ pointerEvents: "none" }}
      />
    </div>
  )
}

LottieAnimation.propTypes = {
  animation: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
}

LottieAnimation.defaultProps = {
  width: undefined,
  height: undefined,
}

export default LottieAnimation
