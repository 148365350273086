import axios from "axios"

import config from "../utils/config"

const whitelabelId = config.REACT_APP_PRODUCT_ID

function getUserData() {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/me`)
}

function loginUser(email, password, recaptcha, hash, iframe) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/login`, {
    email,
    password,
    whitelabelId,
    recaptcha,
    hash,
    iframe,
  })
}

function registerUser(email, password, fullName, companyName, phone, invitationId, hash) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/register`, {
    email,
    password,
    fullName,
    team: companyName,
    phone,
    whitelabelId,
    invitationId,
    hash,
  })
}

function logoutUser(userID) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/logout`)
}

function addAccount(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/register`, data)
}

function changePassword(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/change_password`, data)
}

function resetPassword(email) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/reset_password_email`, { email })
}

function setPassword(data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/reset_password`, data)
}

function resendConfirmationEmail(userID) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/resend_confirmation`)
}

function getAllUsers(search) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users${search}`)
}

function getUserProduct(userID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/product`)
}

function crateSavedReply(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/saved_reply`, data)
}

function editSavedReply(userID, savedReplyId, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/saved_reply/${savedReplyId}`,
    data,
  )
}

function deleteSavedReply(userID, savedReplyId) {
  return axios.delete(`${config.REACT_APP_BACKEND_URL}/users/${userID}/saved_reply/${savedReplyId}`)
}

function getAllSavedReplies(userID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/saved_reply`)
}

function getAllFeatureRequests(userID, query = "") {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/feature_requests${query}`)
}

function getFeatureRequestByID(userID, featureID = "") {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/feature_requests/${featureID}`)
}

function createFeatureRequest(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/feature_requests`, data)
}

function voteForFeatureRequest(userID, featureID) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/feature_requests/${featureID}/vote`,
  )
}

function getActiveUser(userID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}`)
}

function deleteUser(userID) {
  return axios.delete(`${config.REACT_APP_BACKEND_URL}/users/${userID}`)
}

function changeUserName(userID, name) {
  return axios.patch(`${config.REACT_APP_BACKEND_URL}/users/${userID}/name`, { name })
}

function addFeatureRequestComment(userID, featureID = "", data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/feature_requests/${featureID}/comment`,
    data,
  )
}

function getLinkedinUser(userID, accountID, linkedinUserId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/linkedin_users/${linkedinUserId}`,
  )
}

function getOpenApiKey(userID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/api_key`)
}

function deactivateTwoFactor(userID) {
  return axios.delete(`${config.REACT_APP_BACKEND_URL}/users/${userID}/two_factor_auth/deactivate`)
}

function twoFactorAuthVerify(userID, totpToken) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/two_factor_auth/verify`,
    totpToken,
  )
}

function generateTwoFactorSecretUri(userID) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/two_factor_auth/activate`)
}

function twoFactorAuthSetup(userID, data) {
  return axios.post(`${config.REACT_APP_BACKEND_URL}/users/${userID}/two_factor_auth/confirm`, data)
}

export default {
  loginUser,
  registerUser,
  getUserData,
  logoutUser,
  addAccount,
  changePassword,
  resetPassword,
  setPassword,
  resendConfirmationEmail,
  getAllUsers,
  getUserProduct,
  crateSavedReply,
  editSavedReply,
  deleteSavedReply,
  getAllSavedReplies,
  getAllFeatureRequests,
  getFeatureRequestByID,
  createFeatureRequest,
  voteForFeatureRequest,
  getActiveUser,
  deleteUser,
  changeUserName,
  addFeatureRequestComment,
  getLinkedinUser,
  getOpenApiKey,
  deactivateTwoFactor,
  twoFactorAuthVerify,
  generateTwoFactorSecretUri,
  twoFactorAuthSetup,
}
