const CHAT_FILTER_MESSAGE_TYPE_OPTIONS = [
  {
    name: "LinkedIn messages",
    type: "LINKEDIN_MESSAGE",
  },
  {
    name: "Email messages",
    type: "EMAIL",
  },
]

const CHAT_TYPE_FILTER_DEFAULT_OPTIONS = {
  ALL: { value: "all", name: "All campaigns" },
  UNREAD: { value: "unread", name: "All unread messages" },
  ARCHIVED: { value: "archived", name: "All archived messages" },
  UNREPLIED: { value: "unreplied", name: "All unreplied messages" },
  CONNECTED_NOT_REPLIED: { value: "connected_not_replied", name: "All connected, not replied" },
  OTHER: { value: "other", name: "All other messages" },
}

const DEFAULT_PROFILE_IMAGE =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/600px-Circle-icons-profile.svg.png"

/** If image is like this data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7 then it represents a single pixel and should be replaced */
const LINKEDIN_DEFAULT_PROFILE_IMAGE_V1 =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

const initialAllMessages = { messages: [], channels: [], loadingChatMessages: false }

const initialSelectedConversation = {
  linkedinUser: { fullName: "" },
  labels: [],
  lead: {},
  belongedLeads: [],
  allMessages: initialAllMessages,
}

export {
  CHAT_FILTER_MESSAGE_TYPE_OPTIONS,
  CHAT_TYPE_FILTER_DEFAULT_OPTIONS,
  DEFAULT_PROFILE_IMAGE,
  LINKEDIN_DEFAULT_PROFILE_IMAGE_V1,
  initialAllMessages,
  initialSelectedConversation,
}
