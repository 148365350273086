import React from "react"

const lettersArray = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

const AB_TESTING_TABS_COUNT = 5

const AB_TESTING_ELEMENTS = ["connect", "message", "email", "inMail"]

const specificLinkedInSettingsConstants = [
  {
    name: "Discover Premium LinkedIn accounts only",
    field: "onlyPremium",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline"],
    hasInfoIcon: false,
  },
  {
    name: "Discover Leads with Open Profile status only",
    field: "onlyOpenInmail",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline"],
    hasInfoIcon: false,
  },
  {
    name: "Collect contact information",
    field: "getPersonalInfo",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    hasInfoIcon: true,
    hoverText: (
      <span>
        Collect publicly available contact information from the leads' LinkedIn profiles (e.g.
        email, phone number, Twitter, or website).This option automatically adds a{" "}
        <span className="tooltip-campaign-settings-text">View profile </span>
        after the <span className="tooltip-campaign-settings-text">Invite to connect </span> step
        into the campaign.
      </span>
    ),
    tooltipTop: 160,
  },
  {
    name: "Remove leads with pending connection requests",
    field: "noPendingConnections",
    showFor: ["1", "2"],
    hasInfoIcon: true,
    hoverText: "Leads with pending connection requests on LinkedIn will not be discovered.",
    tooltipTop: 87,
  },
  {
    name: "Refresh search automatically",
    field: "autoReuse",
    showFor: ["1", "2", "recruiter", "post-engagement", "recruiter-pipeline", "leads-list"],
    hasInfoIcon: false,
  },
]

const targetingOptionsConstants = [
  {
    name: "Include leads that replied to your messages",
    field: "sendPrevious",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline", "import", "csv", "leads-list"],
    hasInfoIcon: true,
    hoverText:
      "Include all leads you previously had a conversation with via LinkedIn messages, InMails, or email.",
    tooltipTop: 87,
  },
  {
    name: "Include leads found in other campaigns from your and your team seats",
    field: "includeGloballyTargetedLeads",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    hasInfoIcon: true,
    hoverText: (
      <span>
        Lead source items that match your or global blacklists are always automatically removed.
      </span>
    ),
    tooltipTop: 140,
  },
  {
    name: "Discover new leads only",
    field: "onlyUniqueLeads",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline", "post-engagement", "leads-list"],
    hasInfoIcon: true,
    hoverText: "Leads that exist in other campaigns in your seat will not be discovered.",
    tooltipTop: 70,
  },
]

const emailTrackingPreferenceConstants = [
  {
    name: "Track the number of email link clicks",
    field: "trackEmailsClicks",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
  },
  {
    name: "Track the number of opened emails",
    field: "trackEmailsOpens",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
  },
  {
    name: "Text only email (no HTML)",
    field: "textOnlyEmails",
    showFor: [
      "1",
      "2",
      "post-engagement",
      "recruiter",
      "leads-list",
      "recruiter-pipeline",
      "import",
      "csv",
    ],
    hasInfoIcon: true,
    hoverText:
      "Send email messages that only include text without images, graphics, or formatting. If you enable this option, you won't be able to track open and link click rates.",
    tooltipTop: 124,
  },
]

const defaultAutoReuseInterval = 30

const maxFileSize = 6 * 1024 * 1024

export {
  lettersArray,
  AB_TESTING_TABS_COUNT,
  AB_TESTING_ELEMENTS,
  specificLinkedInSettingsConstants,
  targetingOptionsConstants,
  emailTrackingPreferenceConstants,
  defaultAutoReuseInterval,
  maxFileSize,
}
