import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import { SIGNATURE_EDITOR } from "../../constants/email-constants"
import emailService from "../../services/email-service"
import dataUtils from "../../utils/data-utils"
import { resetCustomSMTPFormData } from "../../utils/email-account-utils"
import { getTeamAccounts, hideInfoModal } from "../actions"

function getActionSuccess(actionType, payload) {
  return { type: actionType, payload }
}

function getEmailAccounts(query = "?data=extended", initLoad = true) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const emailAccounts = await emailService.getEmailAccounts(userID, accountID, query)
      dispatch(
        getActionSuccess(ACTIONS.GET_EMAIL_ACCOUNTS, { ...emailAccounts.data.result, initLoad }),
      )
    } catch (error) {
      return false
    }
  }
}

function getAllEmailAccounts(query = "?data=minimal&status=ACTIVE") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const allEmailAccounts = await emailService.getAllEmailAccounts(userID, accountID, query)
      dispatch(getActionSuccess(ACTIONS.GET_ALL_EMAIL_ACCOUNTS, allEmailAccounts.data.result))
    } catch (error) {
      return false
    }
  }
}

function clearAllEmailAccounts() {
  return {
    type: ACTIONS.CLEAR_ALL_EMAIL_ACCOUNTS,
  }
}

function removeEmailAccount(emailAccountID, type) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const success = await emailService.removeEmailAccount(userID, accountID, emailAccountID, type)
      if (success) {
        dispatch(getActionSuccess(ACTIONS.REMOVE_EMAIL_ACCOUNT, emailAccountID))
        toast.success("Changes successfully submitted")
        dispatch(getTeamAccounts())
        return true
      }
    } catch (error) {
      toast.error("Something went wrong")
      return false
    }
  }
}

function testCustomDomain(domain) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const query = `?customDomain=${domain}`
      const testResult = await emailService.testCustomDomain(userID, accountID, query)
      const { result } = testResult.data
      dispatch(getActionSuccess(ACTIONS.TEST_CUSTOM_DOMAIN, result))
      return result
    } catch (error) {
      return false
    }
  }
}

function resetEmailAccountSettings() {
  return { type: ACTIONS.RESET_EMAIL_ACCOUNT_SETTINGS }
}

function getEmailAccountSettings(mailboxId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID

      const response = await emailService.getEmailAccountSettings(userID, accountID, mailboxId)

      let emailAccountSettings = response.data.result
      const { accountSignature } = emailAccountSettings
      if (
        accountSignature &&
        accountSignature.length &&
        accountSignature[0]?.signatureType === SIGNATURE_EDITOR.RICH_TEXT
      ) {
        const parsedAccountSignature = accountSignature.map(signature => {
          const doc = new DOMParser().parseFromString(signature.signature, "text/html")
          const parsedSignature = dataUtils.getEditCampaignHTML(
            doc.getElementsByTagName("body").item(0),
          )
          return { ...signature, signature: parsedSignature }
        })
        emailAccountSettings = { ...emailAccountSettings, accountSignature: parsedAccountSignature }
      }

      dispatch(getActionSuccess(ACTIONS.GET_EMAIL_ACCOUNT_SETTINGS, emailAccountSettings))
      return emailAccountSettings
    } catch (error) {
      dispatch(resetEmailAccountSettings())
      return false
    }
  }
}

function editEmailAccountSettings(data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await emailService.editEmailAccountSettings(userID, accountID, data)
      dispatch(getActionSuccess(ACTIONS.EDIT_EMAIL_ACCOUNT_SETTINGS, null))
      toast.success("Changes successfully submitted")
      await dispatch(getEmailAccounts())
      return true
    } catch (error) {
      return false
    }
  }
}

function getTeamEmailCredits() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const teamEmailCredits = await emailService.getTeamEmailCredits(userID, teamID)
      if (teamEmailCredits) {
        dispatch(getActionSuccess(ACTIONS.GET_TEAM_EMAIL_CREDITS, teamEmailCredits.data.result))
      }
      return teamEmailCredits.data.result
    } catch (error) {
      return false
    }
  }
}

function getEmailSchedules(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      dispatch(getActionSuccess(ACTIONS.RESET_EMAIL_SCHEDULES_FETCHED_STATUS))
      const emailSchedules = await emailService.getEmailSchedules(userID, teamID, query)
      if (emailSchedules) {
        dispatch(getActionSuccess(ACTIONS.GET_EMAIL_SCHEDULES, emailSchedules.data.result))
        return true
      }
    } catch (error) {
      return false
    }
  }
}

function getCampaignEmailSchedule(campaignId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const emailSchedules = await emailService.getCampaignEmailSchedule(
        userID,
        accountID,
        campaignId,
      )
      if (emailSchedules) {
        dispatch(getActionSuccess(ACTIONS.GET_CAMPAIGN_EMAIL_SCHEDULE, emailSchedules.data.result))
        return true
      }
    } catch (error) {
      return false
    }
  }
}

function clearCampaignEmailSchedule() {
  return {
    type: ACTIONS.CLEAR_CAMPAIGN_EMAIL_SCHEDULE,
  }
}

function getEmailScheduleCampaigns(scheduleId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const emailScheduleCampaigns = await emailService.getEmailScheduleCampaigns(
        userID,
        teamID,
        scheduleId,
      )
      if (emailScheduleCampaigns) {
        dispatch(
          getActionSuccess(
            ACTIONS.GET_EMAIL_SCHEDULE_CAMPAIGNS,
            emailScheduleCampaigns.data.result,
          ),
        )
        return true
      }
    } catch (error) {
      return false
    }
  }
}

function getEmailScheduleTemplates(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const emailScheduleTemplates = await emailService.getEmailSchedulesTemplates(
        userID,
        teamID,
        query,
      )
      if (emailScheduleTemplates) {
        dispatch(
          getActionSuccess(
            ACTIONS.GET_EMAIL_SCHEDULE_TEMPLATES,
            emailScheduleTemplates.data.result,
          ),
        )
        return true
      }
    } catch (error) {
      return false
    }
  }
}

function clearEmailScheduleCampaigns() {
  return {
    type: ACTIONS.CLEAR_EMAIL_SCHEDULE_CAMPAIGNS,
  }
}

function createEmailSchedule(schedule, showNotification = true) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const createdEmailSchedule = await emailService.createEmailSchedule(userID, teamID, schedule)
      if (createdEmailSchedule) {
        dispatch(getActionSuccess(ACTIONS.CREATE_EMAIL_SCHEDULE, createdEmailSchedule.data.result))
        if (schedule.saveAsTeamTemplate) {
          dispatch(
            getActionSuccess(
              ACTIONS.CREATE_EMAIL_SCHEDULE_TEMPLATE,
              createdEmailSchedule.data.result,
            ),
          )
        }
        if (showNotification) {
          toast.success("Changes successfully submitted")
        }
        return createdEmailSchedule.data.result
      }
    } catch (error) {
      return false
    }
  }
}

function updateEmailSchedule(scheduleId, scheduleChanges) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const updatedEmailSchedule = await emailService.updateEmailSchedule(
        userID,
        teamID,
        scheduleId,
        scheduleChanges,
      )
      if (scheduleChanges.saveAsTeamTemplate) {
        dispatch(getEmailScheduleTemplates())
      }
      if (updatedEmailSchedule) {
        dispatch(
          getActionSuccess(ACTIONS.UPDATE_EMAIL_SCHEDULE, {
            id: scheduleId,
            changes: scheduleChanges,
          }),
        )
        toast.success("Changes successfully submitted")
        return true
      }
    } catch (error) {
      return false
    }
  }
}

function deleteEmailSchedule(scheduleId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      await emailService.deleteEmailSchedule(userID, teamID, scheduleId)
      dispatch(getActionSuccess(ACTIONS.DELETE_EMAIL_SCHEDULE, scheduleId))
      toast.success("Changes successfully submitted")
      return true
    } catch (error) {
      return false
    }
  }
}

function createEmailScheduleTemplate(schedule, fromExistingSchedule = false) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const createdEmailScheduleTemplate = await emailService.createEmailScheduleTemplate(
        userID,
        teamID,
        schedule,
      )
      if (createdEmailScheduleTemplate) {
        dispatch(
          getActionSuccess(
            ACTIONS.CREATE_EMAIL_SCHEDULE_TEMPLATE,
            createdEmailScheduleTemplate.data.result,
          ),
        )
        if (fromExistingSchedule) {
          toast.success("Changes successfully submitted")
        }
        return true
      }
    } catch (error) {
      return false
    }
  }
}

function deleteEmailScheduleTemplate(scheduleId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      await emailService.deleteEmailScheduleTemplate(userID, teamID, scheduleId)
      dispatch(getActionSuccess(ACTIONS.DELETE_EMAIL_SCHEDULE_TEMPLATE, scheduleId))
      toast.success("Changes successfully submitted")
      return true
    } catch (error) {
      return false
    }
  }
}

function getSmtpImapCredentials(mailboxId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const result = await emailService.getSmtpImapCredentials(userID, accountID, mailboxId)
      const smtpImapCredentials = result.data.result
      dispatch(getActionSuccess(ACTIONS.GET_SMTP_IMAP_CREDENTIALS, smtpImapCredentials))
      return smtpImapCredentials
    } catch (error) {
      return false
    }
  }
}

function reconnectImapSmtp(mailboxId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const {
        "smtp-sender-email": senderEmail,
        "smtp-sender-name": name,
        "smtp-username": user,
        "smtp-password": pass,
        "smtp-ip": host,
        "smtp-port": port,
        "imap-username": userImap,
        "imap-password": passImap,
        "imap-ip": hostImap,
        "imap-port": portImap,
        smtpSsl,
      } = getState().forms.formData

      const data = {
        senderEmail,
        name,
        user,
        pass,
        host,
        port,
        userImap,
        passImap,
        hostImap,
        portImap,
        certificate: smtpSsl ? "tls" : "none",
        certificateImap: smtpSsl ? "tls" : "none",
      }

      await emailService.reconnectImapSmtp(userID, accountID, mailboxId, data)
      dispatch(getEmailAccounts())
      toast.success("SMTP successfully reconnect")
      dispatch(hideInfoModal())
      resetCustomSMTPFormData()
      return true
    } catch (error) {
      return false
    }
  }
}

export {
  getEmailAccounts,
  getAllEmailAccounts,
  clearAllEmailAccounts,
  removeEmailAccount,
  testCustomDomain,
  resetEmailAccountSettings,
  getEmailAccountSettings,
  editEmailAccountSettings,
  getTeamEmailCredits,
  getEmailSchedules,
  getCampaignEmailSchedule,
  clearCampaignEmailSchedule,
  getEmailScheduleCampaigns,
  clearEmailScheduleCampaigns,
  createEmailSchedule,
  updateEmailSchedule,
  deleteEmailSchedule,
  getEmailScheduleTemplates,
  createEmailScheduleTemplate,
  deleteEmailScheduleTemplate,
  getSmtpImapCredentials,
  reconnectImapSmtp,
}
