import "./EmailIntegrationBody.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import InputGroup from "../../organisms/InputGroup"
import Button from "../Button"
import Loader from "../Loader"

const EmailIntegrationBody = ({
  enableSSL,
  onSmtpSubmit,
  emailProviderConnect,
  closeModal,
  onClose,
  templateType,
  integrationType,
}) => {
  const { loadingSmtp, loadingImap, smtpTest, imapTest, smtpSsl } = useSelector(
    state => state.forms.formData,
  )

  const [multiplePasswordType, setMultiplePasswordType] = useState({
    "smtp-password": "password",
    "imap-password": "password",
  })

  const handleClose = () => {
    if (closeModal) {
      onClose()
    } else {
      emailProviderConnect()
    }
  }

  const senderNameClassName = classNames("input-on-surface", {
    "disabled-sender-email": integrationType === "reconnect",
  })

  return (
    <div>
      <p>
        To {integrationType} {templateType} SMTP server, fill out the required fields.
      </p>
      <br />
      <h5>Sender settings</h5>
      <Row>
        <Col>
          <InputGroup
            field="smtp-sender-email"
            label="Sender email"
            placeholder="user@domain.com"
            inputGroupClassName={senderNameClassName}
            disabled={integrationType === "reconnect"}
          />
        </Col>
        <Col>
          <InputGroup
            field="smtp-sender-name"
            label="Sender name"
            placeholder="First / Last name"
            inputGroupClassName="input-on-surface"
          />
        </Col>
      </Row>
      <br />
      <Row>
        {loadingSmtp ? (
          <Col className="loading">
            <Loader fullWidth />
          </Col>
        ) : (
          <Col>
            <h5>SMTP settings</h5>
            <InputGroup
              field="smtp-username"
              label="Username"
              placeholder="user@domain.com"
              inputGroupClassName="input-on-surface"
            />
            <InputGroup
              field="smtp-password"
              label="Password"
              placeholder="Enter password"
              inputGroupClassName="input-on-surface"
              type={multiplePasswordType["smtp-password"]}
              multiplePasswordType={multiplePasswordType}
              setMultiplePasswordType={setMultiplePasswordType}
            />
            <InputGroup
              field="smtp-ip"
              label="IP address"
              placeholder="smtp.domain.com"
              inputGroupClassName="input-on-surface"
            />
            <InputGroup
              field="smtp-port"
              label="Port number"
              placeholder="Enter Port number (ie. 465)"
              inputGroupClassName="input-on-surface"
            />

            <div>
              <label className="checkmark-container">
                Use TLS/SSL
                <input
                  type="radio"
                  name="smtpSsl"
                  checked={smtpSsl}
                  onChange={async () => {
                    enableSSL("smtpSsl", true)
                  }}
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="mt-2">
              <label className="checkmark-container">
                Don't use encryption
                <input
                  type="radio"
                  name="webhooks"
                  checked={!smtpSsl}
                  onChange={() => {
                    enableSSL("smtpSsl", false)
                  }}
                />
                <span className="checkmark" />
              </label>
            </div>
          </Col>
        )}
        {loadingImap ? (
          <Col className="loading">
            <Loader fullWidth />
          </Col>
        ) : (
          <Col>
            <h5>IMAP settings</h5>
            <InputGroup
              field="imap-username"
              label="Username"
              placeholder="user@domain.com"
              inputGroupClassName="input-on-surface"
            />
            <InputGroup
              field="imap-password"
              label="Password"
              placeholder="Enter password"
              inputGroupClassName="input-on-surface"
              type={multiplePasswordType["imap-password"]}
              multiplePasswordType={multiplePasswordType}
              setMultiplePasswordType={setMultiplePasswordType}
            />
            <InputGroup
              field="imap-ip"
              label="IP address"
              placeholder="imap.domain.com"
              inputGroupClassName="input-on-surface"
            />
            <InputGroup
              field="imap-port"
              label="Port number"
              placeholder="Enter Port number (ie. 993)"
              inputGroupClassName="input-on-surface"
            />
          </Col>
        )}
      </Row>
      <div className="smtp-errors-class mt-4">
        {smtpTest != null && (
          <div className="error-message-container">
            {smtpTest ? (
              <span className="error-icon-message success-icon">{"\u2714"}</span>
            ) : (
              <span className="error-icon-message error-icon">!</span>
            )}

            {smtpTest ? (
              <span style={{ color: "green" }}>SMTP has connected successfully</span>
            ) : (
              <span style={{ color: "red" }}> SMTP has failed</span>
            )}
          </div>
        )}
        {imapTest != null && (
          <div className="error-message-container">
            {imapTest ? (
              <span className="error-icon-message success-icon">{"\u2714"}</span>
            ) : (
              <span className="error-icon-message error-icon">!</span>
            )}

            {imapTest ? (
              <span style={{ color: "green" }}>IMAP has connected successfully</span>
            ) : (
              <span style={{ color: "red" }}> IMAP has failed</span>
            )}
          </div>
        )}
      </div>
      <br />
      <div className="buttons-container">
        <Button
          variant="secondary"
          active={false}
          type="button"
          margin="0px 120px 0px 0px"
          className="close-button"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button onClick={onSmtpSubmit} type="submit" hasLoading>
          {integrationType.charAt(0).toUpperCase() + integrationType.slice(1)} SMTP
        </Button>
      </div>
    </div>
  )
}

EmailIntegrationBody.propTypes = {
  enableSSL: PropTypes.func.isRequired,
  onSmtpSubmit: PropTypes.func.isRequired,
  emailProviderConnect: PropTypes.func,
  closeModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  templateType: PropTypes.string,
  integrationType: PropTypes.string.isRequired,
}

EmailIntegrationBody.defaultProps = {
  emailProviderConnect: () => {},
  templateType: "",
}

export default EmailIntegrationBody
