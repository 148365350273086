import "./InfoModal.scss"

import classNames from "classnames"
import React from "react"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"

import { hideInfoModal, updateFormField } from "../../../redux/actions"
import Button from "../../atoms/Button"
import ModalWrapper from "../../atoms/ModalWrapper"
import SvgIcon from "../../atoms/SvgIcon"

const InfoModal = ({ infoModalDataProps }) => {
  const dispatch = useDispatch()
  const { infoModalDataRedux } = useSelector(state => ({
    infoModalDataRedux: state.app.infoModalData,
  }))

  const handleCloseInfoModalOnSuccess = () => {
    const infoModalData = infoModalDataProps || infoModalDataRedux
    if (!infoModalData.noAutoHide) {
      dispatch(hideInfoModal(infoModalData?.modalId))
    }

    if (infoModalData.onClose && !infoModalData.skipClose) {
      infoModalData.onClose()
    }
  }

  const handleCloseInfoModal = () => {
    const infoModalData = infoModalDataProps || infoModalDataRedux
    if (!infoModalData.noAutoHide) {
      dispatch(hideInfoModal(infoModalData?.modalId))
    }

    if (infoModalData.onClose) {
      infoModalData.onClose()
    }
  }

  const renderIconByType = () => {
    const { type } = infoModalDataProps || infoModalDataRedux

    const modalTypeClassName = `modal-${type}`
    switch (type) {
      case "success":
        return <SvgIcon icon="checked" className={`info-modal-icon ${modalTypeClassName}`} />
      case "warning":
        return <SvgIcon icon="warning" className={`info-modal-icon ${modalTypeClassName}`} />
      case "info":
        return <SvgIcon icon="info" className={`info-modal-icon ${modalTypeClassName}`} />
      case "error":
        return <SvgIcon icon="error" className={`info-modal-icon ${modalTypeClassName}`} />

      default:
        return null
    }
  }

  const onSubmit = async event => {
    const infoModalData = infoModalDataProps || infoModalDataRedux
    const { onSuccess, noHideOnSubmit = false, noLoading = false } = infoModalData
    if (!noLoading) {
      dispatch(updateFormField("loadingButton", true))
    }
    const isSuccess = await onSuccess(event)

    if (!noLoading) {
      dispatch(updateFormField("loadingButton", false))
    }
    if (!noHideOnSubmit && isSuccess !== false) {
      handleCloseInfoModalOnSuccess()
    }
  }

  const infoModalData = infoModalDataProps || infoModalDataRedux

  const {
    show: showInfoModal,
    header,
    className,
    body,
    type,
    onSuccess,
    actionButtonText = "Submit",
    grayButtonAction = handleCloseInfoModal,
    grayButtonText = "Cancel",
    actionButtonVariant = "primary",
    noCloseButton,
    maxZIndex /** this is when you have multiple modals appearing one over the other */,
    disabled,
    formWrap = false,
  } = infoModalData

  const modalClassName = classNames("info-modal-wrapper", { [className]: className })
  const backdropClassName = classNames("info-modal-backdrop", { maxZIndex })

  const modalTypeClassName = `modal-${type}`
  const ModalIcon = renderIconByType

  const { actionButtonProps } = infoModalDataProps || infoModalDataRedux || {}
  return (
    <Modal
      className={modalClassName}
      show={showInfoModal}
      onHide={handleCloseInfoModal}
      backdropClassName={backdropClassName}
      style={{ whiteSpace: "pre-line" }}
    >
      <ModalWrapper formWrap={formWrap}>
        {header && (
          <Modal.Header className={modalTypeClassName}>
            <button
              className={`close-button ${
                type === "error" ? "close-btn-error-color" : "close-btn-color"
              }`}
              onClick={handleCloseInfoModal}
            >
              <SvgIcon icon="close-icon-solid" />
            </button>
            <Modal.Title className="d-block m-auto">{header}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <ModalIcon />
          <span className="w-100">{body}</span>
        </Modal.Body>
        <Modal.Footer>
          {onSuccess ? (
            <div className="d-flex justify-content-center w-100 justify-buttons">
              {!noCloseButton && (
                <Button
                  type="button"
                  center
                  variant="secondary"
                  active={false}
                  onClick={grayButtonAction}
                >
                  {grayButtonText}
                </Button>
              )}
              <Button
                disabled={disabled}
                center
                variant={actionButtonVariant}
                onClick={onSubmit}
                hasLoading
                type="submit"
                buttonProps={actionButtonProps}
              >
                {actionButtonText}
              </Button>
            </div>
          ) : (
            !noCloseButton && (
              <Button
                type="button"
                center
                variant="secondary"
                active={false}
                onClick={grayButtonAction}
              >
                {grayButtonText}
              </Button>
            )
          )}
        </Modal.Footer>
      </ModalWrapper>
    </Modal>
  )
}

export default withRouter(React.memo(InfoModal))
