const getConfigEnv = (env = {}) => {
  const defaultEnv = {
    REACT_APP_BACKEND_PAYMENT_URL: env?.REACT_APP_BACKEND_URL?.replace(
      "/api/v1",
      "/api/payment/v1",
    ),
    REACT_APP_HIDE_BILLING_ACCOUNT_IDS: "[]",
    REACT_APP_AUTH_SIDE_IMAGE:
      "https://linkedin-aws.s3.us-east-2.amazonaws.com/fdb39986-48a7-4642-89b6-abbd16b62d67",
    REACT_APP_AUTH_SIDE_IMAGE_POSITION: "center",
    REACT_APP_AUTH_SIDE_IMAGE_FULL_SCREEN: false,
    REACT_APP_AUTH_SECOND_LINK_COLOR: "#edb106",
    REACT_APP_IS_GMAIL_DISABLED: false,
    REACT_APP_HIDE_ANNOUNCEMENT_MODAL: false,
    REACT_APP_HELP_CENTER_LINK: null,
    REACT_APP_HIDE_OTHER_MESSAGES: false,
    REACT_APP_GTM_ID: false,
    REACT_APP_UNDER_MAINTENANCE: false,
  }

  if (
    typeof env?.REACT_APP_LOGO_IMAGE_LIGHT_THEME === "undefined" ||
    env.REACT_APP_LOGO_IMAGE_LIGHT_THEME === "undefined" ||
    env.REACT_APP_LOGO_IMAGE_LIGHT_THEME === null
  ) {
    defaultEnv.REACT_APP_LOGO_IMAGE_LIGHT_THEME = env?.REACT_APP_LOGO_IMAGE
  }

  if (
    typeof env?.REACT_APP_LOGO_IMAGE_DARK_THEME === "undefined" ||
    env.REACT_APP_LOGO_IMAGE_DARK_THEME === "undefined" ||
    env.REACT_APP_LOGO_IMAGE_DARK_THEME === null
  ) {
    defaultEnv.REACT_APP_LOGO_IMAGE_DARK_THEME = env?.REACT_APP_LOGO_IMAGE
  }

  const customEnv = {
    REACT_APP_BACKEND_URL: env?.REACT_APP_BACKEND_URL?.replace("/api/v1", "/api/backend/v1"),
  }

  if (
    typeof env?.REACT_APP_BACKEND_PAYMENT_URL === "undefined" ||
    env.REACT_APP_BACKEND_PAYMENT_URL === "undefined" ||
    env.REACT_APP_BACKEND_PAYMENT_URL === null
  ) {
    customEnv.REACT_APP_BACKEND_PAYMENT_URL = env?.REACT_APP_BACKEND_URL?.replace(
      "/api/v1",
      "/api/payment/v1",
    )
  }

  const ret = {
    ...defaultEnv,
    ...env,
    ...customEnv,
  }

  for (const key in ret) {
    if (Object.hasOwnProperty.call(ret, key)) {
      if (ret[key] === null || typeof ret[key] === "undefined" || ret[key] === "undefined") {
        ret[key] = defaultEnv[key]
      } else if (ret[key] === "true") {
        ret[key] = true
      } else if (ret[key] === "false") {
        ret[key] = false
      }
    }
  }

  return ret
}

export default getConfigEnv(window._env_)

export { getConfigEnv }
