export default {
  whitelabelAccountsLimit: 20,
  notificationsLimit: 5,
  featuresActive: [
    "inbox",
    "analytics",
    "webhooks",
    "campaign-sales",
    "campaign-csv",
    "campaign-post-engagement",
    "campaign-post-lead-list",
    "blacklist",
    "campaign-recruiter",
  ],
  maxLengthImportCSVFileName: 45,
  templatesLimit: 20,
}
