import "./Button.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"

import userBehaviourUtils from "../../../../utils/userBehaviour-utils"
import Loader from "../../Loader"
import SvgIcon from "../../SvgIcon"
import SvgIconRound from "../../SvgIconRound"

const Button = props => {
  const {
    type,
    children,
    onClick,
    active,
    margin,
    padding,
    variant,
    loading,
    disabled,
    small,
    big,
    border,
    className,
    center,
    icon,
    borderRadius,
    color,
    fontSize,
    iconClassName,
    hasLoading,
    tabIndex,
    buttonProps,
    roundIcon,
  } = props
  const ref = useRef(null)
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()
  const [loaderSize, setLoaderSize] = useState()

  const reduxLoading = useSelector(({ forms }) => forms.formData.loadingButton)

  const loadingValue = loading || (hasLoading && reduxLoading)

  const getButtonAriaLabel = useMemo(() => {
    if (typeof children === "string") {
      return children
    }
    return undefined
  }, [children])

  useEffect(() => {
    const current = window.getComputedStyle(ref.current, null)

    const newWidth = parseFloat(current.width)
    const newHeight = parseFloat(current.height)
    const newButtonWidthPx = 20
    setWidth(newWidth + newButtonWidthPx)
    setHeight(newHeight)
    setLoaderSize((newWidth < newHeight ? newWidth : newHeight) * 0.7)
  }, [])

  const onButtonClick = e => {
    if (!loadingValue) {
      onClick(e)
      userBehaviourUtils.buttonPressed(getButtonAriaLabel)
    }
  }

  const buttonClassnames = classNames(`btn ${disabled ? "disabled-button" : ""}`, {
    [className]: className,
    "primary-bg": variant === "primary",
    "secondary-bg": variant === "secondary",
    "regular-btn": variant === "regular",
    "btn-danger": variant === "danger",
    "transparent-btn": variant === "transparent",
    "small-btn": small,
    "big-btn": big,
    "border-btn": border,
    "m-auto d-flex": center,
    active,
  })
  const buttonStyle = {
    margin,
    borderRadius,
    ...(padding && { padding }),
    ...(loadingValue && { width, height, justifyContent: "center" }),
  }

  const buttonTextStyle = {
    display: "flex",
    justifyContent: "center",
    ...(color && { color }),
    ...(fontSize && { fontSize }),
  }

  return (
    <button
      aria-label={getButtonAriaLabel}
      onClick={onButtonClick}
      className={buttonClassnames}
      disabled={disabled || loadingValue}
      style={buttonStyle}
      type={type}
      tabIndex={tabIndex}
      {...buttonProps}
      ref={ref}
    >
      {icon && roundIcon && (
        <SvgIconRound
          icon={icon}
          className="round-icon-button"
          classNameIcon={iconClassName}
          bgColor="transparent"
        />
      )}
      {icon && !roundIcon && (
        <SvgIcon icon={icon} className={iconClassName} bgColor="transparent" />
      )}
      {loadingValue ? (
        <span className="button-loader" style={buttonTextStyle}>
          <Loader loaderSize={loaderSize} /> <span className="btn-text">{children}</span>
        </span>
      ) : (
        <span className="button-text" style={buttonTextStyle}>
          {children}
        </span>
      )}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  center: PropTypes.bool,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "regular",
    "danger",
    "transparent",
    "personalization",
    "small-btn",
  ]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  big: PropTypes.bool,
  border: PropTypes.bool,
  icon: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  fontSize: PropTypes.number,
  type: PropTypes.string,
  iconClassName: PropTypes.string,
  hasLoading: PropTypes.bool,
  buttonProps: PropTypes.instanceOf(Object),
  roundIcon: PropTypes.bool,
  tabIndex: PropTypes.string,
}

Button.defaultProps = {
  className: "",
  onClick: () => {},
  center: false,
  active: false,
  margin: "0 0 0 0",
  padding: undefined,
  variant: "primary",
  loading: false,
  disabled: false,
  small: false,
  big: false,
  border: false,
  icon: "",
  borderRadius: 4,
  color: undefined,
  fontSize: undefined,
  type: "submit",
  iconClassName: "create-campaign-icon",
  hasLoading: false,
  buttonProps: {},
  roundIcon: true,
  tabIndex: "0",
}

export default Button
