// legacy, params has been changed by LinkedIn
const basicSearchFilters = [
  "authorIndustry=",
  "authorCompany=",
  "contactInterest=",
  "facetCity=",
  "facetCompany=",
  "facetConnectionOf=",
  "facetCurrentCompany=",
  "facetCurrentFunction=",
  "facetGeoRegion=",
  "facetGroup=",
  "facetGuides=",
  "facetIndustry=",
  "facetNetwork=",
  "facetNonprofitInterest=",
  "facetPastCompany=",
  "facetProfessionalEvent=",
  "facetProfileLanguage=",
  "facetRegion=",
  "facetSchool=",
  "facetSeniority=",
  "facetServiceCategory=",
  "facetState=",
  "groups=",
  "keywords=",
  "topic=",
]

const relationshipsBasic = [
  'facetNetwork=%5B"O"%5D',
  'facetNetwork=%5B"O"%2C"S"%5D',
  'facetNetwork=%5B"S"%5D',
  'facetNetwork=%5B"F"%5D',
  'facetNetwork=%5B"S"%2C"O"%5D',
  "facetNetwork=%5B%22O%22%5D",
  "facetNetwork=%5B%22O%22%2C%22S%22%5D",
  "facetNetwork=%5B%22S%22%5D",
  "facetNetwork=%5B%22F%22%5D",
  "facetNetwork=%5B%22S%22%2C%22O%22%5D",
  "facetNetwork=%5B%22F%22%2C%22S%22%2C%22O%22%5D",

  'network=%5B"O"%5D',
  'network=%5B"O"%2C"S"%5D',
  'network=%5B"S"%5D',
  'network=%5B"F"%5D',
  'network=%5B"S"%2C"O"%5D',
  "network=%5B%22O%22%5D",
  "network=%5B%22O%22%2C%22S%22%5D",
  "network=%5B%22S%22%5D",
  "network=%5B%22F%22%5D",
  "network=%5B%22S%22%2C%22O%22%5D",
]

const forbiddenNavigatorParams = []

const simpleSequencesSteps = [
  { label: "View profile", action: "view" },
  { label: "Invite to connect", action: "connect" },
  { label: "Message", action: "message" },
  { label: "Email message", action: "email" },
  {
    label: "Find & verify business email via your source",
    action: "findAndVerifyBusinessEmailByYourSource",
  },
  { label: "Find & verify business email via LinkedIn", action: "findAndVerifyEmailByLinkedin" },
  { label: "InMail message", action: "inMail" },
  { label: "Follow", action: "follow" },
]

const smartSequencesConditions = [
  "ifCustom",
  "ifConnected",
  "ifEmailOpened",
  "ifHasEmail",
  "ifHasVerifiedEmail",
  "ifOpenInMail",
  "ifEmailClicked",
]

export {
  basicSearchFilters,
  relationshipsBasic,
  forbiddenNavigatorParams,
  simpleSequencesSteps,
  smartSequencesConditions,
}
