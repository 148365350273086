import PropTypes from "prop-types"
import React from "react"
import { Form } from "react-bootstrap"

const ModalWrapper = ({ formWrap, children }) => {
  if (formWrap) {
    return (
      <Form
        onSubmit={event => {
          event.preventDefault()
        }}
      >
        {children}
      </Form>
    )
  }
  return <>{children}</>
}

ModalWrapper.propTypes = {
  formWrap: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

ModalWrapper.defaultProps = {
  formWrap: false,
}

export default ModalWrapper
