import React from "react"

import EmailIntegrationBody from "../components/atoms/EmailIntegrationBody"
import { SIGNATURE_EDITOR } from "../constants/email-constants"
import * as actions from "../redux/actions"
import { store } from "../redux/store"
import { isObjEmpty } from "./global-utils"
import {
  hasSpecialCharacters,
  validateCustomSmtpServer,
  validateImap,
  validateSmtp,
} from "./validation-utils"

export function populateEmailAccountModal(emailAccountSettings, emailAccountIds) {
  const {
    name,
    bccRecipients,
    accountSignature,
    email,
    type,
    dailyEmailLimit,
    randomAdditionalDelay,
    isCTDUserEnabled,
    CTDStatus,
    domain,
    delayBetweenEmails,
    useSignature,
  } = emailAccountSettings

  const signature =
    accountSignature &&
    accountSignature.length &&
    accountSignature?.[0].signatureType === SIGNATURE_EDITOR.RICH_TEXT
      ? accountSignature[0].signature
      : undefined

  const HTMLSignature =
    accountSignature &&
    accountSignature.length &&
    accountSignature?.[0].signatureType === SIGNATURE_EDITOR.HTML
      ? accountSignature[0].signature
      : undefined

  const hasEmailAccountSettings = !isObjEmpty(emailAccountSettings)
  const hasEmailAccountIds = emailAccountIds && emailAccountIds.length
  const hasCustomTrackingDomainError =
    hasEmailAccountSettings &&
    (CTDStatus === "DNS_RESOLUTION_FAILED" || CTDStatus === "SSL_VERIFICATION_FAILED")

  store.dispatch(
    actions.updateFormFields({
      mailboxIds: hasEmailAccountIds ? emailAccountIds : undefined,
      emailAccount: hasEmailAccountSettings ? email : undefined,
      emailAccountType: hasEmailAccountSettings ? type : undefined,
      isCTDUserEnabled: hasEmailAccountSettings ? isCTDUserEnabled : undefined,
      /** sender name section */
      name: hasEmailAccountSettings ? name : undefined,
      /** campaign settings section */
      dailyEmailLimit: hasEmailAccountSettings ? dailyEmailLimit : undefined,
      randomAdditionalDelay: hasEmailAccountSettings ? randomAdditionalDelay : undefined,
      delayBetweenEmails: hasEmailAccountSettings ? delayBetweenEmails : undefined,
      /** custom tracking domain section */
      hasCustomTrackingDomainErrors: hasCustomTrackingDomainError,
      domain: hasEmailAccountSettings ? domain : undefined,
      /** bcc signature section */
      signature: hasEmailAccountSettings ? signature : undefined,
      HTMLSignature: hasEmailAccountSettings ? HTMLSignature : undefined,
      /** bcc recipients section */
      bccRecipients: hasEmailAccountSettings ? bccRecipients : undefined,
      CTDStatus: hasEmailAccountSettings ? CTDStatus : undefined,
      minWaitingTimeSeconds: !hasEmailAccountSettings && undefined,
      minWaitingTimeMinutes: !hasEmailAccountSettings && undefined,
      useSignature: hasEmailAccountSettings ? useSignature : undefined,
    }),
  )
}

export function clearEmailAccountModal() {
  store.dispatch(actions.clearForm())
}

export function secondsInMinutesAndSeconds(seconds) {
  const convertedMinutes = Math.floor(seconds / 60)
  const convertedSeconds = seconds % 60
  return { convertedMinutes, convertedSeconds }
}

export function minutesAndSecondsInSeconds(minutes, seconds) {
  return parseInt(minutes, 10) * 60 + parseInt(seconds, 10)
}

export function filterUndefinedFormErrorsValues() {
  const errors = store.getState().forms.formErrors
  const multipleEmailModalErrors = [
    "name",
    "dailyEmailLimit",
    "isGreaterThan15Minutes",
    "randomAdditionalDelay",
    "minWaitingTime",
    "domain",
    "bcc-option-section",
  ]

  return Object.fromEntries(
    Object.entries(errors).reduce((access, [key, value]) => {
      if (typeof value !== "undefined" && multipleEmailModalErrors.includes(key)) {
        access.push([key, value])
      }

      return access
    }, []),
  )
}

export function editedData(newData, initialData, mailboxIDS) {
  const differentValues = {}

  for (const key in newData) {
    if (
      Object.hasOwnProperty.call(initialData, key) &&
      initialData[key] !== newData[key] &&
      JSON.stringify(initialData[key]) !== JSON.stringify(newData[key])
    ) {
      differentValues[key] = newData[key]
    }
  }

  if (newData.isCTDUserEnabled) {
    differentValues.domain = newData.domain
  }

  return { ...differentValues, mailboxIds: mailboxIDS }
}

export const resetCustomSMTPFormData = () => {
  store.dispatch(
    actions.updateFormFields({
      "smtp-ip": undefined,
      "smtp-port": undefined,
      "imap-ip": undefined,
      "imap-port": undefined,
      "smtp-sender-email": undefined,
      "smtp-sender-name": undefined,
      "smtp-username": undefined,
      "smtp-password": undefined,
      "imap-username": undefined,
      "imap-password": undefined,
      smtpSsl: undefined,
      smtpTest: undefined,
      imapTest: undefined,
      loadingSmtp: undefined,
      loadingImap: undefined,
    }),
  )
}

export const testSMTPHandler = async (userID, accountID) => {
  store.dispatch(actions.updateFormField("loadingSmtp", true))
  const errors = validateSmtp(store.getState().forms.formData)
  if (!errors) {
    const isValid = await store.dispatch(actions.testSMTP(userID, accountID))
    store.dispatch(actions.updateFormField("smtpTest", isValid))
  }
  store.dispatch(actions.setFormErrors(errors))
  store.dispatch(actions.updateFormField("loadingSmtp", false))
}

export const testImapHandler = async (userID, accountID) => {
  store.dispatch(actions.updateFormField("loadingImap", true))
  const errors = validateImap(store.getState().forms.formData)
  if (!errors) {
    const isValid = await store.dispatch(actions.testImap(userID, accountID))
    store.dispatch(actions.updateFormField("imapTest", isValid))
  }
  store.dispatch(actions.setFormErrors(errors))
  store.dispatch(actions.updateFormField("loadingImap", false))
}

export const onSmtpSubmit = async (integrationType, userID, accountID, mailboxID) => {
  store.dispatch(actions.updateFormFields({ loadingButton: true, smtpTest: null, imapTest: null }))

  const errors = {
    ...validateCustomSmtpServer(store.getState().forms.formData),
    ...hasSpecialCharacters({
      smtpSenderName: store.getState().forms.formData["smtp-sender-name"],
    }),
  }

  if (isObjEmpty(errors)) {
    await Promise.all([testSMTPHandler(userID, accountID), testImapHandler(userID, accountID)])
    const { smtpTest, imapTest } = store.getState().forms.formData
    if (smtpTest && imapTest) {
      if (integrationType === "connect") {
        await store.dispatch(
          actions.authenticateSMTP(window.location.href, userID, accountID, true),
        )
      } else if (integrationType === "reconnect") {
        await store.dispatch(actions.reconnectImapSmtp(mailboxID))
      }
    }
  }
  store.dispatch(actions.setFormErrors(errors))
  store.dispatch(actions.updateFormField("loadingButton", false))
}

const closeModal = () => {
  store.dispatch(actions.clearFormErrors())
  resetCustomSMTPFormData()
  store.dispatch(actions.hideInfoModal())
}

const enableSSL = (field, checked) => {
  store.dispatch(actions.updateFormField(field, checked))
}

export const showCustomSmtpReconnectModal = async (userID, accountID, mailboxID) => {
  const smtpImapCredentials = await store.dispatch(actions.getSmtpImapCredentials(mailboxID))

  const {
    senderEmail,
    senderName,
    user,
    pass,
    host,
    port,
    userImap,
    passImap,
    hostImap,
    portImap,
    certificate,
  } = smtpImapCredentials

  if (!isObjEmpty(smtpImapCredentials)) {
    store.dispatch(
      actions.updateFormFields({
        "smtp-sender-email": senderEmail,
        "smtp-sender-name": senderName,
        "smtp-username": user,
        "smtp-password": pass,
        "smtp-ip": host,
        "smtp-port": port,
        "imap-username": userImap,
        "imap-password": passImap,
        "imap-ip": hostImap,
        "imap-port": portImap,
        smtpSsl: certificate,
      }),
    )

    const integrationType = "reconnect"

    store.dispatch(
      actions.showInfoModal(
        "basic",
        `Reconnect Custom SMTP Server`,
        <div>
          <EmailIntegrationBody
            closeModal
            onClose={closeModal}
            enableSSL={enableSSL}
            onSmtpSubmit={() => onSmtpSubmit(integrationType, userID, accountID, mailboxID)}
            integrationType={integrationType}
          />
        </div>,
        undefined,
        undefined,
        closeModal,
        undefined,
        true,
        true,
        undefined,
        true,
        undefined,
        "smtp-integration-modal",
      ),
    )
  }
}
