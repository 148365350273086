import classNames from "classnames"
import { formatInTimeZone } from "date-fns-tz"
import moment from "moment"
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"

import * as actions from "../redux/actions"
import { store } from "../redux/store"
import { getConfigEnv } from "./config"
import { isValidTimestamp } from "./validation-utils"

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        /** Do nothing if clicking ref's element or descendent elements */
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)

      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    /**
     * Add ref and handler to effect dependencies
     * It's worth noting that because passed in handler is a new function on every render
     * that will cause this effect callback/cleanup to run every render. It's not a big deal,
     * but to optimize you can wrap handler in useCallback before passing it into this hook.
     */
    [ref, handler],
  )
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export function useTooltip() {
  const initialState = {
    top: "-300vh",
    left: "-300vw",
    text: "",
    backgroundColor: "#282828",
    color: `var(${"--text-colorTooltip"})`,
    customStyle: {},
    caret: false,
    caretCenter: false,
    customClassName: {},
    replaceTopWithBottom: false,
    alignItemAbove: false,
    caretTop: false,
  }
  const [tooltipState, setTooltipState] = useState(initialState)
  const [focus, setFocus] = useState(false)

  const tooltipCaret = classNames("caret", {
    "custom-toolbar-caret": !tooltipState.caretTop,
    "caret-top": tooltipState.caretTop,
    [tooltipState.customClassName]: !!tooltipState.customClassName,
  })

  const setTooltip = (
    event,
    text,
    topPos = 60,
    leftPos = 0,
    backgroundColor = `var(${"--on-surface-color"})`,
    customStyle = {},
    caret = false,
    caretCenter = false,
    color = `var(${"--text-colorTooltip"})`,
    customClassName,
    replaceTopWithBottom = false,
    alignItemAbove = false,
    caretTop = false,
  ) => {
    const { top, left, right } = event.currentTarget.getBoundingClientRect()
    setTooltipState(() => ({
      top: replaceTopWithBottom ? `${top - 10}px` : `${top - topPos}px`,
      left: alignItemAbove ? (left + right) / 2 - leftPos : `${left - leftPos}px`,
      text,
      backgroundColor,
      color,
      customStyle,
      caret,
      caretCenter,
      customClassName,
      replaceTopWithBottom,
      alignItemAbove,
      caretTop,
    }))
  }

  const closeTooltip = () => {
    if (!focus) {
      setTooltipState(initialState)
    }
  }

  useEffect(() => {
    closeTooltip()
  }, [focus])

  const tooltip = useMemo(() => {
    return (
      <div
        onMouseEnter={() => {
          setFocus(true)
        }}
        onMouseLeave={() => {
          setFocus(false)
        }}
        style={{
          position: "fixed",
          top: tooltipState.replaceTopWithBottom ? null : tooltipState.top,
          left: tooltipState.left,
          transform: tooltipState.alignItemAbove ? "translateX(-50%)" : "none",
          backgroundColor: tooltipState.backgroundColor,
          cursor: "pointer",
          zIndex: "11001",
          color: tooltipState.color,
          borderRadius: "6px",
          fontSize: "12px",
          border: `1px solid ${tooltipState.backgroundColor}`,
          textAlign: "start",
          padding: "9px",
          ...tooltipState.customStyle,
          bottom: tooltipState.replaceTopWithBottom ? `calc(100vh - ${tooltipState.top})` : null,
        }}
      >
        <p style={{ color: tooltipState.color, margin: 0, padding: 0 }}>
          {typeof tooltipState.text === "string"
            ? tooltipState.text?.split("\n").map(line => (
                <>
                  {line} <br />
                </>
              ))
            : tooltipState.text}
        </p>
        {tooltipState.caret && (
          <span
            className={tooltipCaret}
            style={{
              borderTopColor: tooltipState.backgroundColor,
              ...(tooltipState.caretCenter ? { left: 0, right: 0, margin: "auto" } : {}),
            }}
          />
        )}
      </div>
    )
  }, [setTooltip])

  return [tooltip, setTooltip, closeTooltip]
}

export function useCustomMenu(className) {
  const initialState = {
    top: "-300vh",
    left: "-300vw",
    element: "",
    active: false,
  }

  const elementRef = useRef()

  const [menuState, setMenuState] = useState(initialState)

  const setCustomMenu = (
    event,
    element,
    additionalLeft = 0,
    additionalTop = 0,
    withoutInnerHeightCondition = false,
  ) => {
    const { top, left } = event.target.getBoundingClientRect()
    setMenuState(() => ({
      top:
        top + 345 < window.innerHeight || withoutInnerHeightCondition
          ? `${top + additionalTop}px`
          : `${top + additionalTop - 242}px`,
      left: `${left - 240 + additionalLeft}px`,
      element,
      active: true,
    }))
  }

  useEffect(() => {
    const closeMenuOnClickOutside = e => {
      if (e.target !== elementRef.current && menuState.active) {
        setMenuState(initialState)
      }
    }

    const closeMenuOnScroll = () => {
      setMenuState(initialState)
    }

    document.addEventListener("click", closeMenuOnClickOutside)
    document.addEventListener("scroll", closeMenuOnScroll)

    return () => {
      document.removeEventListener("click", closeMenuOnClickOutside)
      document.removeEventListener("scroll", closeMenuOnScroll)
    }
  }, [menuState.active])

  const customMenu = (
    <div
      ref={elementRef}
      className={className}
      style={{
        position: "fixed",
        top: menuState.top,
        left: menuState.left,
      }}
    >
      {menuState.element}
    </div>
  )

  return [customMenu, setCustomMenu]
}

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, deps)
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener("resize", updateSize)
    updateSize()

    return () => window.removeEventListener("resize", updateSize)
  }, [])

  return size
}

export function usePreloadImages(imageSrcs) {
  const [loading, setLoading] = useState(true)
  const [loadedImages, setLoadedImages] = useState(0)

  useEffect(() => {
    for (const src of imageSrcs) {
      // preload the image
      const img = new Image()
      img.src = src
      img.addEventListener("load", () => {
        if (+imageSrcs.length <= +loadedImages) {
          setLoading(false)
        } else if (+imageSrcs.length > +loadedImages) {
          setLoadedImages(prevState => prevState + 1)
        }
      })
    }
  }, [imageSrcs, loadedImages])

  return loading
}

export function useConfig() {
  // const [config, setConfig] = useState(getConfigEnv(window._env_))

  // useEffect(() => {
  //   setConfig(getConfigEnv(window._env_))
  // }, [])

  return getConfigEnv(window._env_)
}

export const throttle = f => {
  let token = null
  let lastArgs = null

  const invoke = () => {
    f(...lastArgs)
    token = null
  }

  const result = (...args) => {
    lastArgs = args
    if (!token) {
      token = requestAnimationFrame(invoke)
    }
  }

  result.cancel = () => token && cancelAnimationFrame(token)

  return result
}

export const useDraggable = ({ onDrag = x => x, defaultPosition = { x: 0, y: 0 } } = {}) => {
  const [pressed, setPressed] = useState(false)

  const position = useRef(defaultPosition)
  const ref = useRef()

  const unsubscribe = useRef()
  const legacyRef = useCallback(elem => {
    ref.current = elem
    if (unsubscribe.current) {
      unsubscribe.current()
    }

    if (!elem) {
      return
    }

    const handleMouseDown = e => {
      e.target.style.userSelect = "none"
      setPressed(true)
    }

    elem.addEventListener("mousedown", handleMouseDown)

    unsubscribe.current = () => {
      elem.removeEventListener("mousedown", handleMouseDown)
    }
  }, [])

  useEffect(() => {
    if (!pressed) {
      return
    }

    const handleMouseMove = throttle(event => {
      if (!ref.current || !position.current) {
        return
      }

      const pos = position.current
      const elem = ref.current

      position.current = onDrag({
        x: pos.x + event.movementX,
        y: pos.y + event.movementY,
      })

      elem.style.transform = `translate(${pos.x}px, ${pos.y}px)`
    })

    const handleMouseUp = e => {
      e.target.style.userSelect = "auto"
      setPressed(false)
    }

    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("mouseup", handleMouseUp)

    return () => {
      handleMouseMove.cancel()
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("mouseup", handleMouseUp)
    }
  }, [pressed, onDrag])

  return [legacyRef, pressed]
}
export const useCustomWhitelabelComponent = (whitelabelComponents, props) => {
  const config = useConfig()

  const WhitelabelComponent = useMemo(() => {
    const whitelabelId = config.REACT_APP_PRODUCT_ID

    return whitelabelComponents[whitelabelId] || whitelabelComponents[1] || null
  }, [config.REACT_APP_PRODUCT_ID])

  return <WhitelabelComponent {...props} />
}

export const useCampaignScheduleInitialTime = campaignStartDate => {
  const [startDate, setStartDate] = useState(moment())
  const [{ startUTCTime: startUTCTimeRedux, startDate: startDateFromRedux }] = useSelector(
    state => [state.forms.formData],
  )

  const [startUTCTime, setStartUTCTime] = useState(moment().format("HH:mm"))

  useEffect(() => {
    if (startUTCTimeRedux) {
      setStartUTCTime(startUTCTimeRedux)
    }
  }, [startUTCTimeRedux])

  useEffect(() => {
    store.dispatch(actions.updateFormField("startDate", startDate))
  }, [startDate])

  useEffect(() => {
    if (!campaignStartDate) {
      const UTCFromRedux = moment(startUTCTimeRedux, "HH:mm").format("HH:mm")
      if (
        startDateFromRedux &&
        isValidTimestamp(startDateFromRedux) &&
        UTCFromRedux !== "Invalid date"
      ) {
        setStartDate(moment(startDateFromRedux))
        setStartUTCTime(UTCFromRedux)
      }
    }
  }, [campaignStartDate])

  useEffect(() => {
    if (campaignStartDate && isValidTimestamp(campaignStartDate)) {
      setStartDate(
        moment(formatInTimeZone(new Date(campaignStartDate), moment.tz.guess(), "MM-dd-yyyy")),
      )
      setStartUTCTime(formatInTimeZone(new Date(campaignStartDate), moment.tz.guess(), "HH:mm"))
    }
  }, [campaignStartDate])

  return [startDate, setStartDate, startUTCTime]
}

export const useHasUserGuiding = () => {
  const [hasUserGuiding, setHasUserGuiding] = useState(false)

  useEffect(() => {
    const handler = () => {
      const userGuidingContainer = document.querySelector(".userguiding-assistant-container")
      setHasUserGuiding(!!userGuidingContainer)
    }

    handler()

    document.addEventListener("DOMNodeInserted", handler)
    document.addEventListener("DOMNodeRemoved", handler)

    return () => {
      document.removeEventListener("DOMNodeInserted", handler)
      document.removeEventListener("DOMNodeRemoved", handler)
    }
  }, [])

  return hasUserGuiding
}
