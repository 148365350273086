export default {
  /** API actions */
  START_API_CALL: "START_API_CALL",
  API_ERROR: "API_ERROR",
  CLEAR_API_ERROR: "CLEAR_API_ERROR",

  GET_USER_DATA: "GET_USER_DATA",
  USER_LOGIN: "USER_LOGIN",
  USER_REGISTER: "USER_REGISTER",
  USER_LOGOUT: "USER_LOGOUT",
  INIT_FORM: "INIT_FORM",
  CLEAR_FORM: "CLEAR_FORM",
  SET_FORM_ERRORS: "SET_FORM_ERRORS",
  SET_COMPLEX_FORM_ERRORS: "SET_COMPLEX_FORM_ERRORS",
  CLEAR_COMPLEX_STEPS_ERROR: "CLEAR_COMPLEX_STEPS_ERROR",
  CLEAR_FORM_ERROR: "CLEAR_FORM_ERROR",
  CLEAR_FORM_ERRORS: "CLEAR_FORM_ERRORS",
  UPDATE_FORM_FIELD: "UPDATE_FORM_FIELD",
  UPDATE_FORM_FIELDS: "UPDATE_FORM_FIELDS",
  GET_ALL_CAMPAIGNS: "GET_ALL_CAMPAIGNS",
  GET_ALL_ACTIVE_CAMPAIGNS: "GET_ALL_ACTIVE_CAMPAIGNS",
  CLEAR_CSV_INFO: "CLEAR_CSV_INFO",
  GET_LEADS_BY_CAMPAIGN: "GET_LEADS_BY_CAMPAIGN",
  CHANGE_LEAD_ACTIVITY: "CHANGE_LEAD_ACTIVITY",
  DELETE_LEADS: "DELETE_LEADS",
  GET_SETTING_DATA: "GET_SETTING_DATA",
  GET_RUN_ON_WEEKENDS: "GET_RUN_ON_WEEKENDS",
  GET_CAMPAIGN_MESSAGES: "GET_CAMPAIGN_MESSAGES",
  GET_BELONGED_LEADS_FOR_THREAD: "GET_BELONGED_LEADS_FOR_THREAD",
  GET_MESSAGES_FOR_LEADS: "GET_MESSAGES_FOR_LEADS",
  SELECT_CHANNEL: "SELECT_CHANNEL",
  SELECT_CHAT: "SELECT_CHAT",
  SET_MESSAGES: "SET_MESSAGES",
  ADD_NEW_MESSAGE: "ADD_NEW_MESSAGE",
  DELETE_MESSAGE: "DELETE_MESSAGE",
  SET_CHANNELS: "SET_CHANNELS",
  DESELECT_CHAT: "DESELECT_CHAT",
  GET_LINKEDIN_USER_MESSAGES: "GET_LINKEDIN_USER_MESSAGES",
  SET_ACTIVE_ACCOUNT_ID: "SET_ACTIVE_ACCOUNT_ID",
  CLEAR_FILTERED_ITEMS: "CLEAR_FILTERED_ITEMS",
  GET_ALL_WEBHOOKS: "GET_ALL_WEBHOOKS",
  GET_CANCELLATION_REASONS: "GET_CANCELLATION_REASONS",
  GET_ALL_SUBSCRIPTIONS: "GET_ALL_SUBSCRIPTIONS",
  GET_CAMPAIGN_DETAILS: "GET_CAMPAIGN_DETAILS",
  CLEAR_CAMPAIGN_DETAILS: "CLEAR_CAMPAIGN_DETAILS",
  GET_CAMPAIGN_STEPS: "GET_CAMPAIGN_STEPS",
  GET_PENDING_CONNECTIONS: "GET_PENDING_CONNECTIONS",
  GET_MY_TEMPLATES: "GET_MY_TEMPLATES",
  GET_DEFAULT_TEMPLATES: "GET_DEFAULT_TEMPLATES",
  SET_ADDITIONAL_VARIABLES: "SET_ADDITIONAL_VARIABLES",
  CLEAR_ADDITIONAL_VARIABLES: "CLEAR_ADDITIONAL_VARIABLES",
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ALL_CHAT_LABELS: "GET_ALL_CHAT_LABELS",
  GET_LEADS_CHAT_LABELS: "GET_LEADS_CHAT_LABELS",
  ADD_LABEL_TO_LEAD: "ADD_LABEL_TO_LEAD",
  REMOVE_LABEL_FROM_LEAD: "REMOVE_LABEL_FROM_LEAD",
  RELOAD_CHAT_STATE: "RELOAD_CHAT_STATE",
  GET_LAST_FETCHED_CONVERSATION_TIMESTAMP: "GET_LAST_FETCHED_CONVERSATION_TIMESTAMP",
  SEEN_CHAT: "SEEN_CHAT",
  GET_LAST_USED_CARD: "GET_LAST_USED_CARD",
  CLEAR_ALL_USERS: "CLEAR_ALL_USERS",
  GET_ACCOUNT_NOTIFICATIONS: "GET_ACCOUNT_NOTIFICATIONS",
  GET_ACTIVE_ACCOUNT: "GET_ACTIVE_ACCOUNT",
  SEEN_ACCOUNT_NOTIFICATIONS: "SEEN_ACCOUNT_NOTIFICATIONS",
  GET_CAMPAIGN_NAMES: "GET_CAMPAIGN_NAMES",
  GET_PAYMENT_CUSTOMER: "GET_PAYMENT_CUSTOMER",
  GET_ALL_SAVED_REPLIES: "GET_ALL_SAVED_REPLIES",
  GET_ALL_FEATURE_REQUESTS: "GET_ALL_FEATURE_REQUESTS",
  GET_FEATURE_REQUESTS_BY_ID: "GET_FEATURE_REQUESTS_BY_ID",
  REPLACE_CAMPAIGN_MESSAGES: "REPLACE_CAMPAIGN_MESSAGES",
  REMOVE_LINKEDIN_USER_MESSAGE: "REMOVE_LINKEDIN_USER_MESSAGE",
  GET_CSV_INFO: "GET_CSV_INFO",
  SET_CONVERSATIONS: "SET_CONVERSATIONS",
  GET_LEADS_BY_ALL_CAMPAIGNS: "GET_LEADS_BY_ALL_CAMPAIGNS",
  GET_PERSONALIZED_IMAGE_DATA: "GET_PERSONALIZED_IMAGE_DATA",
  UPDATE_CAMPAIGN_ID: "UPDATE_CAMPAIGN_ID",
  REPLACE_CHANNEL: "REPLACE_CHANNEL",
  LOADING_MESSAGES: "LOADING_MESSAGES",
  SET_LINKEDIN_USER_MESSAGE: "SET_LINKEDIN_USER_MESSAGE",
  REPLACE_SELECTED_CONVERSATION_LABELS: "REPLACE_SELECTED_CONVERSATION_LABELS",

  /** App */
  GET_ALL_SUPPORTED_TAGS: "GET_ALL_SUPPORTED_TAGS",
  GET_PROXY_COUNTRIES: "GET_PROXY_COUNTRIES",
  GET_ACCOUNT_STATUSES: "GET_ACCOUNT_STATUSES",
  SHOW_INFO_MODAL: "SHOW_INFO_MODAL",
  SHOW_PREVIOUS_INFO_MODAL: "SHOW_PREVIOUS_INFO_MODAL",
  SET_INFO_MODAL_DATA: "SET_INFO_MODAL_DATA",
  HIDE_INFO_MODAL: "HIDE_INFO_MODAL",
  CHANGE_WHITELABEL_URL: "CHANGE_WHITELABEL_URL",
  TRIGGER_END_FREE_TRIAL_MODAL: "TRIGGER_END_FREE_TRIAL_MODAL",
  GET_WHITELABEL_PERMISSIONS: "GET_WHITELABEL_PERMISSIONS",

  /** User */
  GET_ACTIVE_USER: "GET_ACTIVE_USER",
  GET_SEAT: "GET_SEAT",
  GET_LINKEDIN_USER: "GET_LINKEDIN_USER",
  GET_GLOBAL_STATUS: "GET_GLOBAL_STATUS",
  GET_GLOBAL_STATUS_FOR_ACCOUNT: "GET_GLOBAL_STATUS_FOR_ACCOUNT",
  GET_OPEN_API_KEY: "GET_OPEN_API_KEY",
  CLEAR_LINKEDIN_USER: "CLEAR_LINKEDIN_USER",

  /** Account */
  GET_ACCOUNT_PROXY_INFO: "GET_ACCOUNT_PROXY_INFO",
  START_STREAMING: "START_STREAMING",
  STREAM_CREATED: "STREAM_CREATED",
  FREE_STREAM: "FREE_STREAM",
  STREAM_SUCCESS: "STREAM_SUCCESS",
  STREAM_ERROR: "STREAM_ERROR",
  GET_OUT_OF_OFFICE_EMAILS: "GET_OUT_OF_OFFICE_EMAILS",

  /** Payment */
  GET_PAYMENT_PLANS: "GET_PAYMENT_PLANS",
  GET_AVAILABLE_SEATS_FOR_TEAM: "GET_AVAILABLE_SEATS_FOR_TEAM",
  GET_AVAILABLE_SEATS_FOR_USER: "GET_AVAILABLE_SEATS_FOR_USER",
  GET_ACCOUNT_SPECIFIC_PRICES: "GET_ACCOUNT_SPECIFIC_PRICES",
  GET_PRICE_PREVIEW: "GET_PRICE_PREVIEW",
  GET_TEAMS_WITH_PAYMENT: "GET_TEAMS_WITH_PAYMENT",

  /** Settings */
  SET_ACCOUNT_HEALTH: "SET_ACCOUNT_HEALTH",

  /** Statistics */
  GET_STATISTICS: "GET_STATISTICS",
  GET_CAMPAIGN_STEPS_STATISTICS: "GET_CAMPAIGN_STEPS_STATISTICS",
  GET_STATISTICS_TOTAL_COUNT: "GET_STATISTICS_TOTAL_COUNT",

  /** Campaigns */
  SELECT_DRAFT_CAMPAIGN: "SELECT_DRAFT_CAMPAIGN",
  UPDATE_DRAFT_CAMPAIGN: "UPDATE_DRAFT_CAMPAIGN",
  SET_SELECTED_NODE: "SET_SELECTED_NODE",
  SET_SELECTED_TAB: "SET_SELECTED_TAB",
  GET_SAVED_SEQUENCES: "GET_SAVED_SEQUENCES",
  GET_TEAM_TEMPLATE: "GET_TEAM_TEMPLATE",
  GET_STEP_TEMPLATES: "GET_STEP_TEMPLATES",
  CLEAR_ALL_CAMPAIGN_NAMES: "CLEAR_ALL_CAMPAIGN_NAMES",
  DELETE_CAMPAIGNS: "DELETE_CAMPAIGNS",

  /** Team management */
  CLEAN_CO_WORKER_TEAMS_FOR_INVITE: "CLEAN_CO_WORKER_TEAMS_FOR_INVITE",
  SET_SELECTED_TEAM: "SET_SELECTED_TEAM",
  GET_USER_TEAMS: "GET_USER_TEAMS",
  GET_USER_TEAM: "GET_USER_TEAM",
  CLEAR_USER_TEAMS: "CLEAR_USER_TEAMS",
  GET_TEAM_MEMBERS: "GET_TEAM_MEMBERS",
  GET_CO_WORKERS: "GET_CO_WORKERS",
  GET_CO_WORKER_TEAMS_FOR_INVITE: "GET_CO_WORKER_TEAMS_FOR_INVITE",
  GET_PLATFORM_ADMINS: "GET_PLATFORM_ADMINS",
  GET_TEAM_ACCOUNTS: "GET_TEAM_ACCOUNTS",
  CLEAR_TEAM_ACCOUNTS: "CLEAR_TEAM_ACCOUNTS",
  GET_TEAM_ROLES: "GET_TEAM_ROLES",
  GET_PLATFORM_ROLES: "GET_PLATFORM_ROLES",
  GET_PERMISSIONS: "GET_PERMISSIONS",
  TOGGLE_SUCCESS: "TOGGLE_SUCCESS",
  SET_SELECTED_TEAM_ACCOUNT: "SET_SELECTED_TEAM_ACCOUNT",

  /** Chat */
  GET_LEAD_INFO: "GET_LEAD_INFO",
  CLEAR_DELETED_TAG: "CLEAR_DELETED_TAG",
  SET_CAN_REPLY_TO_EMAIL_THREAD: "SET_CAN_REPLY_TO_EMAIL_THREAD",
  CLEAR_CAN_REPLY_TO_EMAIL_THREAD: "CLEAR_CAN_REPLY_TO_EMAIL_THREAD",
  GET_MESSAGES_FOR_LINKEDIN_USER: "GET_MESSAGES_FOR_LINKEDIN_USER",
  ADD_CAMPAIGN_ID_TO_LEAD: "ADD_CAMPAIGN_ID_TO_LEAD",

  /** Blacklist */
  GET_LINKEDIN_BLACKLIST: "GET_LINKEDIN_BLACKLIST",
  GET_EMAIL_BLACKLIST: "GET_EMAIL_BLACKLIST",
  GET_GLOBAL_LINKEDIN_BLACKLIST: "GET_GLOBAL_LINKEDIN_BLACKLIST",
  GET_GLOBAL_EMAIL_BLACKLIST: "GET_GLOBAL_EMAIL_BLACKLIST",
  ADD_TO_BLACKLIST: "ADD_TO_BLACKLIST",
  ADD_TO_GLOBAL_BLACKLIST: "ADD_TO_GLOBAL_BLACKLIST",
  IMPORT_BLACKLIST_CSV: "IMPORT_BLACKLIST_CSV",
  IMPORT_GLOBAL_BLACKLIST_CSV: "IMPORT_GLOBAL_BLACKLIST_CSV",
  DELETE_BLACKLIST: "DELETE_BLACKLIST",
  DELETE_GLOBAL_BLACKLIST: "DELETE_GLOBAL_BLACKLIST",

  /** Emails */
  GET_EMAIL_ACCOUNTS: "GET_EMAIL_ACCOUNTS",
  GET_ALL_EMAIL_ACCOUNTS: "GET_ALL_EMAIL_ACCOUNTS",
  CLEAR_ALL_EMAIL_ACCOUNTS: "CLEAR_ALL_EMAIL_ACCOUNTS",
  TEST_CUSTOM_DOMAIN: "TEST_CUSTOM_DOMAIN",
  GET_EMAIL_ACCOUNT_SETTINGS: "GET_EMAIL_ACCOUNT_SETTINGS",
  RESET_EMAIL_ACCOUNT_SETTINGS: "RESET_EMAIL_ACCOUNT_SETTINGS",
  EDIT_EMAIL_ACCOUNT_SETTINGS: "EDIT_EMAIL_ACCOUNT_SETTINGS",
  GET_TEAM_EMAIL_CREDITS: "GET_TEAM_EMAIL_CREDITS",
  RESET_EMAIL_SCHEDULES_FETCHED_STATUS: "RESET_EMAIL_SCHEDULES_FETCHED_STATUS",
  GET_EMAIL_SCHEDULES: "GET_EMAIL_SCHEDULES",
  GET_CAMPAIGN_EMAIL_SCHEDULE: "GET_CAMPAIGN_EMAIL_SCHEDULE",
  CLEAR_CAMPAIGN_EMAIL_SCHEDULE: "CLEAR_CAMPAIGN_EMAIL_SCHEDULE",
  GET_EMAIL_SCHEDULE_CAMPAIGNS: "GET_EMAIL_SCHEDULE_CAMPAIGNS",
  CLEAR_EMAIL_SCHEDULE_CAMPAIGNS: "CLEAR_EMAIL_SCHEDULE_CAMPAIGNS",
  CREATE_EMAIL_SCHEDULE: "CREATE_EMAIL_SCHEDULE",
  UPDATE_EMAIL_SCHEDULE: "UPDATE_EMAIL_SCHEDULE",
  DELETE_EMAIL_SCHEDULE: "DELETE_EMAIL_SCHEDULE",
  GET_EMAIL_SCHEDULE_TEMPLATES: "GET_EMAIL_SCHEDULE_TEMPLATES",
  CREATE_EMAIL_SCHEDULE_TEMPLATE: "CREATE_EMAIL_SCHEDULE_TEMPLATE",
  DELETE_EMAIL_SCHEDULE_TEMPLATE: "DELETE_EMAIL_SCHEDULE_TEMPLATE",
  REMOVE_EMAIL_ACCOUNT: "REMOVE_EMAIL_ACCOUNT",
  GET_SMTP_IMAP_CREDENTIALS: "GET_SMTP_IMAP_CREDENTIALS",

  /** Two factor */
  GENERATE_TWO_FACTOR_SECRET_URI: "GENERATE_TWO_FACTOR_SECRET_URI",
}
