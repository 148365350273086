import "./SvgIconRound.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const SvgIconRound = ({
  className,
  classNameIcon,
  style,
  icon,
  bgColor,
  onClick,
  onMouseEnter,
  onMouseLeave,
  whitelabelBackground,
}) => {
  const classNameWrapper = classNames("round-icon", {
    [className]: className,
  })

  const classIcon = classNames("icon-size", {
    [classNameIcon]: classNameIcon,
  })

  return (
    <span
      className={classNameWrapper}
      style={{ backgroundColor: whitelabelBackground ? undefined : bgColor, ...style }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <SvgIcon icon={icon} className={classIcon} onClick={onClick} />
    </span>
  )
}

SvgIconRound.propTypes = {
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  icon: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  whitelabelBackground: PropTypes.bool,
}

SvgIconRound.defaultProps = {
  className: "",
  style: {},
  bgColor: null,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  whitelabelBackground: false,
}

export default SvgIconRound
