import ACTIONS from "../../constants/ACTIONS"
import resourceService from "../../services/resource-service"
import whitelabelService from "../../services/whitelabel-service"
import config from "../../utils/config"
import dataUtils from "../../utils/data-utils"

function getProxyCountriesSuccess(proxyCountries) {
  return { type: ACTIONS.GET_PROXY_COUNTRIES, proxyCountries }
}

function getCancellationReasons(cancellationReasons) {
  return { type: ACTIONS.GET_CANCELLATION_REASONS, cancellationReasons }
}

function getProxyCountries() {
  return async dispatch => {
    try {
      const proxyCountries = await resourceService.getProxyCountries()
      dispatch(getProxyCountriesSuccess(Object.values(proxyCountries.data.result)))
    } catch (error) {}
  }
}

function showInfoModal(
  type,
  header,
  body,
  onSuccess,
  actionButtonText,
  onClose,
  grayButtonText,
  formWrap,
  noHideOnSubmit,
  hasPrevious,
  noCloseButton,
  actionButtonVariant,
  className,
  noAutoHide,
  maxZIndex,
  modalId,
  actionButtonProps,
) {
  return async (dispatch, getState) => {
    try {
      const { infoModalData } = getState().app
      dispatch({
        type: ACTIONS.SHOW_INFO_MODAL,
        infoModalData: {
          show: true,
          type,
          header,
          body,
          onSuccess,
          actionButtonText,
          onClose,
          grayButtonText,
          formWrap,
          noHideOnSubmit,
          hasPrevious,
          previousData: { ...infoModalData },
          noCloseButton,
          actionButtonVariant,
          className,
          noAutoHide,
          maxZIndex,
          modalId,
          actionButtonProps,
        },
      })
    } catch (error) {}
  }
}

function setModalData(modalDataObject) {
  return async (dispatch, getState) => {
    try {
      const { infoModalData } = getState().app
      dispatch({
        type: ACTIONS.SET_INFO_MODAL_DATA,
        infoModalData: {
          ...infoModalData,
          ...modalDataObject,
        },
      })
    } catch (error) {}
  }
}

function hideInfoModal(modalId) {
  return async (dispatch, getState) => {
    try {
      const { infoModalData } = getState().app
      if (modalId !== infoModalData.modalId) {
        return
      }
      let newInfoModalData = {}
      if (infoModalData?.previousData) {
        newInfoModalData = {
          ...infoModalData.previousData,
        }
      } else {
        newInfoModalData = {
          ...infoModalData,
          previousData: {},
          show: false,
          modalId,
        }
      }
      dispatch({
        type: ACTIONS.HIDE_INFO_MODAL,
        infoModalData: newInfoModalData,
      })
    } catch (error) {}
  }
}

function getSupportedCampaignTagsSuccess(allSupportedTags) {
  return { type: ACTIONS.GET_ALL_SUPPORTED_TAGS, allSupportedTags }
}

function getSupportedCampaignTags() {
  return async dispatch => {
    try {
      const allSupportedTags = await resourceService.getSupportedCampaignTags()
      const allUniqueTagsFormatted = dataUtils.formatVariableTags(
        Object.keys(allSupportedTags.data.result),
        allSupportedTags.data.result,
      )
      dispatch(getSupportedCampaignTagsSuccess(allUniqueTagsFormatted))
    } catch (error) {}
  }
}

function getAccountStatusesSuccess(accountStatuses) {
  return { type: ACTIONS.GET_ACCOUNT_STATUSES, accountStatuses }
}

function getAccountStatuses() {
  return async dispatch => {
    try {
      const accountStatuses = await resourceService.getAccountStatuses()

      dispatch(
        getAccountStatusesSuccess([
          {
            statusId: -1,
            statusName: "CONNECT",
            userMessage: "Connect your linkedin account.",
          },
          ...Object.values(accountStatuses.data.result),
        ]),
      )
    } catch (error) {}
  }
}

function changeWhitelabelEnvSuccess(whitelabelUrl) {
  return { type: ACTIONS.CHANGE_WHITELABEL_URL, whitelabelUrl }
}

function changeWhitelabelEnv(whitelabelUrl) {
  return async dispatch => {
    try {
      dispatch(changeWhitelabelEnvSuccess(whitelabelUrl))
    } catch (error) {}
  }
}

function getAllCancellationReasons() {
  return async dispatch => {
    try {
      const reasons = await resourceService.getCancellationReasons()
      dispatch(getCancellationReasons(reasons.data.result))
    } catch (error) {}
  }
}

const triggerEndFreeTrialModal = value => {
  return { type: ACTIONS.TRIGGER_END_FREE_TRIAL_MODAL, payload: value }
}

const getWhitelabelPermissions = () => {
  return async dispatch => {
    try {
      const whitelabelId = config.REACT_APP_PRODUCT_ID
      if (whitelabelId) {
        const response = await whitelabelService.getWhitelabelPermissions(whitelabelId)
        const permissions = response?.data?.result?.items || []
        dispatch({
          type: ACTIONS.GET_WHITELABEL_PERMISSIONS,
          payload: permissions,
        })
      }
    } catch {}
  }
}

export {
  getProxyCountries,
  showInfoModal,
  setModalData,
  hideInfoModal,
  getSupportedCampaignTags,
  getAccountStatuses,
  changeWhitelabelEnv,
  getAllCancellationReasons,
  triggerEndFreeTrialModal,
  getWhitelabelPermissions,
}
