import PropTypes from "prop-types"

import { store } from "../redux/store"
import { hasWhitelabelPermission, WHITELABEL_PERMISSIONS } from "./whitelabel-utils"

/** START: Feature related constants */
const CUSTOM_ROLE_LIMIT = 10
const DEFAULT_ROLE_LIMIT = 3
const ROLE_LIMIT = CUSTOM_ROLE_LIMIT + DEFAULT_ROLE_LIMIT
const USER_TYPES = {
  TEAM_MEMBER: {
    key: "TEAM_MEMBER",
    value: "team member",
    userTypeId: 1,
  },
  CO_WORKER: {
    key: "CO_WORKER",
    value: "co-worker",
    userTypeId: 2,
  },
  PLATFORM_ADMIN: {
    key: "PLATFORM_ADMIN",
    value: "platform admin",
    userTypeId: 3,
  },
  SUPER_ADMIN: {
    key: "SUPER_ADMIN",
    value: "super admin",
    userTypeId: 4,
  },
}

const TEAM_MANAGEMENT_MODALS = {
  INVITE_TEAM_MEMBER: {
    key: "INVITE_TEAM_MEMBER",
    title: "Invite a team member",
  },
  INVITE_CO_WORKER: {
    key: "INVITE_CO_WORKER",
    title: "Invite a co-worker",
  },
  INVITE_PLATFORM_ADMIN: {
    key: "INVITE_PLATFORM_ADMIN",
    title: "Invite a platform admin",
  },
  EDIT_TEAM_MEMBER: {
    key: "EDIT_TEAM_MEMBER",
    title: "Edit a team member",
  },
  EDIT_CO_WORKER: {
    key: "EDIT_CO_WORKER",
    title: "Edit a co-worker",
  },
  CREATE_CUSTOM_ROLE: {
    key: "CREATE_CUSTOM_ROLE",
    title: "Create a custom role",
  },
  CREATE_CUSTOM_ROLE_SUCCESS: {
    key: "CREATE_CUSTOM_ROLE_SUCCESS",
    title: "Create custom role",
  },
  EDIT_CUSTOM_ROLE: {
    key: "EDIT_CUSTOM_ROLE",
    title: "Edit custom role",
  },
}

const TEAM_MANAGEMENT_TABS = {
  TEAM_MEMBERS: {
    key: "TEAM_MEMBERS",
    name: "Team members",
    buttonLabel: "Invite Team Member",
    buttonDisclaimer: "",
    buttonAction: null,
    modal: TEAM_MANAGEMENT_MODALS.INVITE_TEAM_MEMBER,
    emptyTableMessage:
      "You don't have team members. Start by inviting your first team member to collaborate.",
  },
  CO_WORKERS: {
    key: "CO_WORKERS",
    name: "Co-workers",
    buttonLabel: "Invite Co-worker",
    buttonDisclaimer: "",
    buttonAction: null,
    modal: TEAM_MANAGEMENT_MODALS.INVITE_CO_WORKER,
    emptyTableMessage:
      "You don't have co-workers. Start by inviting your first co-worker to collaborate.",
  },
  PLATFORM_ADMINS: {
    key: "PLATFORM_ADMINS",
    name: "Platform admins",
    buttonLabel: "Invite Platform Admin",
    buttonDisclaimer: "",
    buttonAction: null,
    modal: TEAM_MANAGEMENT_MODALS.INVITE_PLATFORM_ADMIN,
    emptyTableMessage:
      "You don't have platform admins. Start by inviting your first platform admin to collaborate.",
  },
  ROLES_AND_PERMISSIONS: {
    key: "ROLES_AND_PERMISSIONS",
    name: "Roles & permissions",
    buttonLabel: "Create Custom Role",
    buttonDisclaimer: "up to 10 customized roles",
    buttonAction: null,
    modal: TEAM_MANAGEMENT_MODALS.CREATE_CUSTOM_ROLE,
  },
}

const TEAM_MANAGEMENT_TOOLTIPS = {
  EDIT_TEAM_MEMBER: "edit-team-member",
  DELETE_TEAM_MEMBER: "delete-team-member",
  TEAM_MEMBER_PAYMENT_PERMISSION: "team-member-payment-permission",
  CO_WORKER_PAYMENT_PERMISSION: "co-worker-payment-permission",
  MANAGED_SEATS: "managed-seats",
}
/** END: Feature related constants */

/** START: Custom Prop validators */
const OPTIONAL_BUTTON_FUNCTION_PROP_TYPE = (props, propName) => {
  if (
    props.showButton &&
    (typeof props[propName] === "undefined" || typeof props[propName] !== "function")
  ) {
    return new Error("Please provide an onButtonClick function!")
  }
}

const PERMISSION_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  viewOnly: PropTypes.shape({
    available: PropTypes.bool,
    selected: PropTypes.bool,
  }),
})
/** END: Custom Prop validators */

const BE_PERMISSIONS = {
  MANAGE_WEBHOOKS: "Manage webhooks",
  MANAGE_OTHER_MESSAGES: "Manage other messages",
  MANAGE_GLOBAL_LIMITS: "Manage global limits",
  MANAGE_BLACKLIST: "Manage blacklist",
  MANAGE_CHAT: "Manage chat",
  MANAGE_DELETE_SEAT: "Delete seat",
  MANAGE_CAMPAIGNS: "Manage campaigns",
  MANAGE_CANCEL_SUBSCRIPTION: "Cancel subscription",
  MANAGE_CAMPAIGN_DETAILS_AND_REPORTS: "Manage campaign details and reports",
  MANAGE_SEAT_SETTINGS: "Manage seat settings",
  MANAGE_OPEN_LINKEDIN_PROFILE: "Open LinkedIn profile",
  MANAGE_LINKEDIN_INTEGRATIONS: "Manage LinkedIn integrations",
  MANAGE_ACCOUNT_HEALTH: "Manage account health",
  MANAGE_EMAIL_SETTINGS: "Manage email settings",
}

const getBePermission = permission => {
  const { permissions } = store.getState().teamManagement
  const foundPermission = permissions.find(p => p.id === permission.id)

  if (!foundPermission) {
    return null
  }

  const bePermission = Object.keys(BE_PERMISSIONS).find(
    key => BE_PERMISSIONS[key] === foundPermission?.name,
  )
  return BE_PERMISSIONS[bePermission]
}

const PERMISSIONS = {
  CAMPAIGNS_VIEW: "CAMPAIGNS_VIEW",
  CAMPAIGNS_EDIT: "CAMPAIGNS_EDIT",
  CAMPAIGN_DETAIL_VIEW: "CAMPAIGN_DETAIL_VIEW",
  CAMPAIGN_DETAIL_EDIT: "CAMPAIGN_DETAIL_EDIT",
  CAMPAIGN_REPORTS_VIEW: "CAMPAIGN_REPORTS_VIEW",
  CAMPAIGN_REPORTS_EDIT: "CAMPAIGN_REPORTS_EDIT",
  CHAT_VIEW: "CHAT_VIEW",
  CHAT_EDIT: "CHAT_EDIT",
  CHAT_OTHER_MESSAGES_VIEW: "CHAT_OTHER_MESSAGES_VIEW",
  CHAT_OTHER_MESSAGES_EDIT: "CHAT_OTHER_MESSAGES_EDIT",
  WEBHOOKS_VIEW: "WEBHOOKS_VIEW",
  WEBHOOKS_EDIT: "WEBHOOKS_EDIT",
  SETTINGS_VIEW: "SETTINGS_VIEW",
  SETTINGS_EDIT: "SETTINGS_EDIT",
  LINKEDIN_SETTINGS_VIEW: "LINKEDIN_SETTINGS_VIEW",
  LINKEDIN_SETTINGS_EDIT: "LINKEDIN_SETTINGS_EDIT",
  GLOBAL_LIMITS_VIEW: "GLOBAL_LIMITS_VIEW",
  GLOBAL_LIMITS_EDIT: "GLOBAL_LIMITS_EDIT",
  ACCOUNT_HEALTH_VIEW: "ACCOUNT_HEALTH_VIEW",
  ACCOUNT_HEALTH_EDIT: "ACCOUNT_HEALTH_EDIT",
  LINKEDIN_INTEGRATIONS_VIEW: "LINKEDIN_INTEGRATIONS_VIEW",
  LINKEDIN_INTEGRATIONS_EDIT: "LINKEDIN_INTEGRATIONS_EDIT",
  EMAIL_SETTINGS_VIEW: "EMAIL_SETTINGS_VIEW",
  EMAIL_SETTINGS_EDIT: "EMAIL_SETTINGS_EDIT",
  BLACKLIST_VIEW: "BLACKLIST_VIEW",
  BLACKLIST_EDIT: "BLACKLIST_EDIT",
  /** Account settings refers to the whole account settings modal on master login page */
  ACCOUNT_SETTINGS_VIEW: "ACCOUNT_SETTINGS_VIEW",
  ACCOUNT_SETTINGS_EDIT: "ACCOUNT_SETTINGS_EDIT",
  /** Seat settings refers only to seat name and time zone options within the account settings modal on master login page */
  SEAT_SETTINGS_VIEW: "SEAT_SETTING_VIEW",
  SEAT_SETTINGS_EDIT: "SEAT_SETTINGS_EDIT",
  CANCEL_SUBSCRIPTION_VIEW: "CANCEL_SUBSCRIPTION_VIEW",
  CANCEL_SUBSCRIPTION_EDIT: "CANCEL_SUBSCRIPTION_EDIT",
  DELETE_ACCOUNT_VIEW: "DELETE_ACCOUNT_VIEW",
  DELETE_ACCOUNT_EDIT: "DELETE_ACCOUNT_EDIT",
  OPEN_LINKEDIN_PROFILE_VIEW: "OPEN_LINKEDIN_PROFILE_VIEW",
  OPEN_LINKEDIN_PROFILE_EDIT: "OPEN_LINKEDIN_PROFILE_EDIT",
}

const GLOBAL_PERMISSIONS = {
  PAYMENT: "canManagePayment",
  PLATFORM_ROLES: "hasPlatformRoles",
  BLACKLIST: "canManageGlobalBlacklist",
}

export const getUserType = userTypeId => {
  return Object.values(USER_TYPES).find(userType => userType.userTypeId === userTypeId)
}

const getTeamPermissions = (team, accountId) => {
  const teamPermissions = []
  if (team && team.accounts && team.accounts.length) {
    team.accounts.forEach(teamAccount => {
      if (teamAccount.id?.toString() === accountId?.toString()) {
        if (teamAccount.permissions && teamAccount.permissions.length) {
          teamAccount.permissions.forEach(permission => {
            const bePermission = getBePermission(permission)
            switch (bePermission) {
              case BE_PERMISSIONS.MANAGE_CAMPAIGNS:
                teamPermissions.push(PERMISSIONS.CAMPAIGNS_VIEW)
                if (!permission.isViewOnly) {
                  teamPermissions.push(PERMISSIONS.CAMPAIGNS_EDIT)
                }
                break
              case BE_PERMISSIONS.MANAGE_CAMPAIGN_DETAILS_AND_REPORTS:
                teamPermissions.push(PERMISSIONS.CAMPAIGN_DETAIL_VIEW)
                if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.ANALYTICS)) {
                  teamPermissions.push(PERMISSIONS.CAMPAIGN_REPORTS_VIEW)
                }
                if (!permission.isViewOnly) {
                  teamPermissions.push(PERMISSIONS.CAMPAIGN_DETAIL_EDIT)
                  if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.ANALYTICS)) {
                    teamPermissions.push(PERMISSIONS.CAMPAIGN_REPORTS_EDIT)
                  }
                }
                break
              case BE_PERMISSIONS.MANAGE_CHAT:
                if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.INBOX)) {
                  teamPermissions.push(PERMISSIONS.CHAT_VIEW)
                  if (!permission.isViewOnly) {
                    teamPermissions.push(PERMISSIONS.CHAT_EDIT)
                  }
                }
                break
              case BE_PERMISSIONS.MANAGE_OTHER_MESSAGES:
                if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.INBOX)) {
                  teamPermissions.push(PERMISSIONS.CHAT_OTHER_MESSAGES_VIEW)
                  if (!permission.isViewOnly) {
                    teamPermissions.push(PERMISSIONS.CHAT_OTHER_MESSAGES_EDIT)
                  }
                }
                break
              case BE_PERMISSIONS.MANAGE_WEBHOOKS:
                if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.WEBHOOKS)) {
                  teamPermissions.push(PERMISSIONS.WEBHOOKS_VIEW)
                  if (!permission.isViewOnly) {
                    teamPermissions.push(PERMISSIONS.WEBHOOKS_EDIT)
                  }
                }
                break
              case BE_PERMISSIONS.MANAGE_GLOBAL_LIMITS:
                if (!teamPermissions.includes(PERMISSIONS.SETTINGS_VIEW)) {
                  teamPermissions.push(PERMISSIONS.SETTINGS_VIEW)
                }
                if (!teamPermissions.includes(PERMISSIONS.LINKEDIN_SETTINGS_VIEW)) {
                  teamPermissions.push(PERMISSIONS.LINKEDIN_SETTINGS_VIEW)
                }
                teamPermissions.push(PERMISSIONS.GLOBAL_LIMITS_VIEW)
                if (!permission.isViewOnly) {
                  teamPermissions.push(PERMISSIONS.GLOBAL_LIMITS_EDIT)
                }
                break
              case BE_PERMISSIONS.MANAGE_ACCOUNT_HEALTH:
                if (!teamPermissions.includes(PERMISSIONS.SETTINGS_VIEW)) {
                  teamPermissions.push(PERMISSIONS.SETTINGS_VIEW)
                }
                if (!teamPermissions.includes(PERMISSIONS.LINKEDIN_SETTINGS_VIEW)) {
                  teamPermissions.push(PERMISSIONS.LINKEDIN_SETTINGS_VIEW)
                }
                teamPermissions.push(PERMISSIONS.ACCOUNT_HEALTH_VIEW)
                if (!permission.isViewOnly) {
                  teamPermissions.push(PERMISSIONS.ACCOUNT_HEALTH_EDIT)
                }
                break
              case BE_PERMISSIONS.MANAGE_LINKEDIN_INTEGRATIONS:
                if (!teamPermissions.includes(PERMISSIONS.SETTINGS_VIEW)) {
                  teamPermissions.push(PERMISSIONS.SETTINGS_VIEW)
                }
                if (!teamPermissions.includes(PERMISSIONS.LINKEDIN_SETTINGS_VIEW)) {
                  teamPermissions.push(PERMISSIONS.LINKEDIN_SETTINGS_VIEW)
                }
                teamPermissions.push(PERMISSIONS.LINKEDIN_INTEGRATIONS_VIEW)
                if (!permission.isViewOnly) {
                  teamPermissions.push(PERMISSIONS.LINKEDIN_INTEGRATIONS_EDIT)
                }
                break
              case BE_PERMISSIONS.MANAGE_EMAIL_SETTINGS:
                if (!teamPermissions.includes(PERMISSIONS.SETTINGS_VIEW)) {
                  teamPermissions.push(PERMISSIONS.SETTINGS_VIEW)
                }
                teamPermissions.push(PERMISSIONS.EMAIL_SETTINGS_VIEW)
                if (!permission.isViewOnly) {
                  teamPermissions.push(PERMISSIONS.EMAIL_SETTINGS_EDIT)
                }
                break
              case BE_PERMISSIONS.MANAGE_BLACKLIST:
                if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.BLACKLIST)) {
                  if (!teamPermissions.includes(PERMISSIONS.SETTINGS_VIEW)) {
                    teamPermissions.push(PERMISSIONS.SETTINGS_VIEW)
                  }
                  teamPermissions.push(PERMISSIONS.BLACKLIST_VIEW)
                  if (!permission.isViewOnly) {
                    teamPermissions.push(PERMISSIONS.BLACKLIST_EDIT)
                  }
                }
                break
              case BE_PERMISSIONS.MANAGE_SEAT_SETTINGS:
                if (!teamPermissions.includes(PERMISSIONS.ACCOUNT_SETTINGS_VIEW)) {
                  teamPermissions.push(PERMISSIONS.ACCOUNT_SETTINGS_VIEW)
                }
                teamPermissions.push(PERMISSIONS.SEAT_SETTINGS_VIEW)
                if (!permission.isViewOnly) {
                  teamPermissions.push(PERMISSIONS.SEAT_SETTINGS_EDIT)
                }
                break
              case BE_PERMISSIONS.MANAGE_CANCEL_SUBSCRIPTION:
                if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.CANCEL_SUBSCRIPTION)) {
                  if (!teamPermissions.includes(PERMISSIONS.ACCOUNT_SETTINGS_VIEW)) {
                    teamPermissions.push(PERMISSIONS.ACCOUNT_SETTINGS_VIEW)
                  }
                  teamPermissions.push(PERMISSIONS.CANCEL_SUBSCRIPTION_VIEW)
                  if (!permission.isViewOnly) {
                    teamPermissions.push(PERMISSIONS.CANCEL_SUBSCRIPTION_EDIT)
                  }
                }
                break
              case BE_PERMISSIONS.MANAGE_DELETE_SEAT:
                if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.DELETE_SEAT)) {
                  if (!teamPermissions.includes(PERMISSIONS.ACCOUNT_SETTINGS_VIEW)) {
                    teamPermissions.push(PERMISSIONS.ACCOUNT_SETTINGS_VIEW)
                  }
                  teamPermissions.push(PERMISSIONS.DELETE_ACCOUNT_VIEW)
                  if (!permission.isViewOnly) {
                    teamPermissions.push(PERMISSIONS.DELETE_ACCOUNT_EDIT)
                  }
                }
                break
              case BE_PERMISSIONS.MANAGE_OPEN_LINKEDIN_PROFILE:
                if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.LINKEDIN_STREAM)) {
                  teamPermissions.push(PERMISSIONS.OPEN_LINKEDIN_PROFILE_VIEW)
                  if (!permission.isViewOnly) {
                    teamPermissions.push(PERMISSIONS.OPEN_LINKEDIN_PROFILE_EDIT)
                  }
                }
                break
              default:
                break
            }
          })
        }
      }
    })
  }
  return teamPermissions
}

const hasPermission = (permission, accountId) => {
  const { selectedTeam } = store.getState().teamManagement
  const activeAccountID = accountId || store.getState().account.activeAccountID
  const teamPermissions = getTeamPermissions(selectedTeam, activeAccountID)
  return !!teamPermissions.find(teamPermission => teamPermission === permission)
}

const isValidEmail = value => {
  return value.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

const getSearchResults = (activeTab, seats, search = "") => {
  if (!search) {
    return seats
  }
  const formattedSearch = search.trim()?.toLowerCase()
  const found = []
  if (seats?.length) {
    seats.forEach(seat => {
      const seatName = activeTab === TEAM_MANAGEMENT_TABS.CO_WORKERS ? seat.teamName : seat.fullName
      if (seatName?.toLowerCase().indexOf(formattedSearch) >= 0) {
        found.push(seat)
      }
    })
  }
  return found
}

const getFirstEligibleAccountUrl = (user, selectedTeam, person) => {
  const baseUrl = `/users/${user.id}/teams/${selectedTeam?.teamId}/accounts/${person.id}`
  if (hasPermission(PERMISSIONS.CAMPAIGNS_VIEW)) {
    return `${baseUrl}`
  }
  if (hasPermission(PERMISSIONS.CAMPAIGN_DETAIL_VIEW)) {
    return `${baseUrl}/selected-campaigns`
  }
  if (hasPermission(PERMISSIONS.CAMPAIGN_REPORTS_VIEW)) {
    return `${baseUrl}/statistics`
  }
  if (hasPermission(PERMISSIONS.CHAT_VIEW)) {
    return `${baseUrl}/chat`
  }
  if (hasPermission(PERMISSIONS.WEBHOOKS_VIEW)) {
    return `${baseUrl}/webhooks`
  }
  if (hasPermission(PERMISSIONS.SETTINGS_VIEW)) {
    return `${baseUrl}/settings`
  }
  if (hasPermission(PERMISSIONS.OPEN_LINKEDIN_PROFILE_VIEW)) {
    return `${baseUrl}/stream`
  }
  if (hasWhitelabelPermission(WHITELABEL_PERMISSIONS.FEATURE_SUGGESTIONS)) {
    return `${baseUrl}/feature-requests`
  }
  return null
}

export {
  USER_TYPES,
  ROLE_LIMIT,
  PERMISSIONS,
  GLOBAL_PERMISSIONS,
  TEAM_MANAGEMENT_TABS,
  TEAM_MANAGEMENT_MODALS,
  TEAM_MANAGEMENT_TOOLTIPS,
  OPTIONAL_BUTTON_FUNCTION_PROP_TYPE,
  PERMISSION_PROP_TYPE,
  hasPermission,
  getSearchResults,
  isValidEmail,
  getFirstEligibleAccountUrl,
}
