import axios from "axios"

import config from "../utils/config"

function getAllCampaigns(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns${query}`,
  )
}

function getAllActiveCampaigns(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/get_active_names_and_ids`,
  )
}

function updateDraftCampaignAndLeadSources(userID, accountID, campaignID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/update_campaign_and_lead_sources`,
    data,
  )
}

function updateDraftCampaign(userID, accountID, campaignID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/update`,
    data,
  )
}
function setCampaignMailboxes(userID, accountID, campaignID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/edit_mailboxes_attached_to_campaign`,
    data,
  )
}

function createCampaign(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns`,
    data,
  )
}

function createLeadSource(userID, accountID, leadSources) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/lead_sources`,
    { leadSources },
  )
}

function getLeadsByCampaign(userID, accountID, campaignID, sort) {
  return axios.get(
    `${
      config.REACT_APP_BACKEND_URL
    }/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/leads${sort || ""}`,
  )
}

function changeLeadVariables(userID, accountID, leadID, data) {
  return axios.put(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/variables`,
    data,
  )
}

function deleteCampaign(userID, accountID, campaignID) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}`,
  )
}

function blockLead(userID, accountID, leadID) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/block`,
  )
}

function blockLeads(userID, accountID, leadIds) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/block?leadIds=[${leadIds}]`,
  )
}

function unblockLead(userID, accountID, leadID) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/unblock`,
  )
}

function unblockLeads(userID, accountID, leadIds) {
  return axios.patch(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/unblock?leadIds=[${leadIds}]`,
  )
}

function deleteLeads(userID, accountID, leadIDS) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/delete?leadIds=[${leadIDS}]`,
  )
}

function deleteCampaigns(userID, accountID, campaignIds) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/delete`,
    { campaignIds },
  )
}

function activateCampaign(userID, accountID, campaignID) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/activate`,
  )
}

function deactivateCampaign(userID, accountID, campaignID) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/deactivate`,
  )
}

function changeCampaignState(userID, accountID, campaignID, campaignState) {
  return axios.patch(
    `
  ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/change_campaign_state`,
    campaignState,
  )
}

function importValidation(file, userID, accountID) {
  const formData = new FormData()
  formData.append("lead_import", file)
  return axios.post(
    `
    ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/validations/lead_import`,
    formData,
  )
}

function getExportFile(userID, accountID, campaignID) {
  return axios.get(
    `
      ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/export`,
  )
}

async function importFile(userID, accountID, campaignID, dashboard, file, removeDuplicates) {
  const formData = new FormData()
  formData.append("lead_import", file)
  formData.append("dashboard", dashboard)
  formData.append("removeDuplicates", removeDuplicates)
  await axios.post(
    `
      ${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/import`,
    formData,
  )
}

function getCampaignDetails(userID, accountID, campaignID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/details`,
  )
}

function getAllCampaignSteps(userID, accountID, campaignID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/steps`,
  )
}

function editCampaign(userID, accountID, campaignID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignID}/edit`,
    data,
  )
}

function getAllCampaignNames(userID, accountID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/names`,
  )
}

function addLeadBackToCampaign(userID, accountID, leadID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads/${leadID}/change_campaign`,
    data,
  )
}

function reuseLeadList(userID, accountID, leadSources) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/lead_sources/reuse_lead_list`,
    { leadSources },
  )
}

function scheduleAutoReuse(userID, accountID, leadSources) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/lead_sources/schedule_auto_reuse`,
    { leadSources },
  )
}

function validateLeadSources(userID, accountID, leadSource) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/lead_sources/validate_lead_sources`,
    { leadSources: [leadSource] },
  )
}

function getLeadsByAllCampaigns(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/leads${query}`,
  )
}
function getMySavedSequences(userID, teamID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/saved_sequences/${query}`,
  )
}

async function getStepTemplates(userID, query) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/saved_steps/${query}`)
}

function getGlobalSavedSequences(userID, whitelabelID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/whitelabel/${whitelabelID}/saved_sequences_global/${query}`,
  )
}

function exportAllCampaignData(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/export`,
    data,
  )
}

function createMyTemplate(userID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/saved_sequences`,
    data,
  )
}

function deleteMyTemplate(userID, sequenceId) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/saved_sequences/${sequenceId}`,
  )
}

function saveStepTemplate(userID, teamID, accountID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/teams/${teamID}/users/${userID}/accounts/${accountID}/saved_steps`,
    data,
  )
}

function deleteStepTemplate(userID, stepId) {
  return axios.delete(`${config.REACT_APP_BACKEND_URL}/users/${userID}/saved_steps/${stepId}`)
}

export default {
  getAllCampaigns,
  updateDraftCampaignAndLeadSources,
  getAllActiveCampaigns,
  updateDraftCampaign,
  setCampaignMailboxes,
  createCampaign,
  createLeadSource,
  getLeadsByCampaign,
  changeLeadVariables,
  deleteCampaign,
  blockLead,
  blockLeads,
  unblockLead,
  unblockLeads,
  deleteLeads,
  activateCampaign,
  deactivateCampaign,
  importValidation,
  getExportFile,
  importFile,
  getCampaignDetails,
  getAllCampaignSteps,
  editCampaign,
  getAllCampaignNames,
  addLeadBackToCampaign,
  changeCampaignState,
  reuseLeadList,
  scheduleAutoReuse,
  validateLeadSources,
  getLeadsByAllCampaigns,
  getMySavedSequences,
  getStepTemplates,
  exportAllCampaignData,
  createMyTemplate,
  deleteMyTemplate,
  saveStepTemplate,
  deleteStepTemplate,
  getGlobalSavedSequences,
  deleteCampaigns,
}
