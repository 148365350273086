import { useCustomWhitelabelComponent } from "../../../utils/hook"
import Advisorlinq from "./15"
import Main from "./Main"

const whitelabelComponents = {
  1: Main,
  15: Advisorlinq,
}
export default props => {
  return useCustomWhitelabelComponent(whitelabelComponents, props)
}
