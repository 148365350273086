export const LEADS_FILTERS = {
  NOT_VERIFIED_EMAILS: {
    name: "NOT_VERIFIED_EMAILS",
    text: "Leads with not verified emails",
  },
  VERIFIED_EMAILS: {
    name: "VERIFIED_EMAILS",
    text: "Leads with verified emails",
  },
  DISCOVERED: {
    name: "DISCOVERED",
    id: 1,
    backgroundColor: "rgba(105, 137, 174, 1)",
    text: "Discovered",
    leadStatus: true,
  },
  CONNECTED_PENDING: {
    name: "CONNECTED_PENDING",
    id: 2,
    backgroundColor: "rgba(236, 178, 93, 1)",
    text: "Connection pending",
    leadStatus: true,
  },
  CONNECTED_NOT_REPLIED: {
    name: "CONNECTED_NOT_REPLIED",
    id: 3,
    backgroundColor: "rgba(121, 79, 132, 1)",
    text: "Connected, not replied",
    leadStatus: true,
  },
  CONNECTED_REPLIED: {
    name: "CONNECTED_REPLIED",
    id: 4,
    backgroundColor: "rgba(19, 183, 132, 1)",
    text: "Replied, connected",
    leadStatus: true,
  },
  REPLIED_NOT_CONNECTED: {
    name: "REPLIED_NOT_CONNECTED",
    id: 5,
    backgroundColor: "rgba(251, 220, 234, 1)",
    text: "Replied, not connected",
    leadStatus: true,
  },
  COMPLEX_STEPS: {
    name: "COMPLEX_STEPS",
    text: "Selected steps",
  },
  NAME: {
    name: "NAME",
    text: "Full name",
  },
  COMPANY: {
    name: "COMPANY",
    text: "Company",
  },
  OCCUPATION: {
    name: "OCCUPATION",
    text: "Occupation",
  },
  HEADLINE: {
    name: "HEADLINE",
    text: "Headline",
  },
  OUT_OF_OFFICE: {
    name: "OUT_OF_OFFICE",
    id: 6,
    backgroundColor: "rgba(251, 240, 222, 1)",
    text: "Out of office",
    leadStatus: true,
  },
  ADVANCED: {
    name: "ADVANCED",
    text: "advanced",
  },
}

export const advancedFilters = [
  LEADS_FILTERS.NAME,
  LEADS_FILTERS.COMPANY,
  LEADS_FILTERS.OCCUPATION,
  LEADS_FILTERS.HEADLINE,
]

export const getAllLeadStatuses = () => {
  return Object.values(LEADS_FILTERS).filter(leadFilter => leadFilter.leadStatus)
}
