import "./Loader.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import loadingBlueDots from "../../../constants/animation/loading-dots-in-blue.json"
import LottieAnimation from "../LottieAnimation"

const Loader = ({ fullWidth, text, margin, below, loaderSize }) => {
  const loaderClass = classNames("loader-container", { fullWidth })
  return (
    <div className={loaderClass}>
      <div className="loader-wrapper" style={{ margin }}>
        <LottieAnimation
          animation={loadingBlueDots}
          width={loaderSize || 41}
          height={loaderSize || 41}
        />
        {text && <span className="loading-text noselect-text">{text}</span>}
      </div>
      {below && <div>{below}</div>}
    </div>
  )
}

Loader.propTypes = {
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  text: PropTypes.string,
  below: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  loaderSize: PropTypes.number,
}

Loader.defaultProps = {
  fullWidth: false,
  text: "",
  margin: undefined,
  below: undefined,
  loaderSize: undefined,
}

export default Loader
