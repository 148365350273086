import "./DarkThemeSwitch.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { updateFormField } from "../../../redux/actions"
import { useConfig } from "../../../utils/hook"
import SvgIcon from "../SvgIcon"

const DarkThemeSwitch = ({ expand, containerStyle }) => {
  const dispatch = useDispatch()
  const config = useConfig()
  const themeFromLocalStorage = localStorage.getItem("theme")

  const [theme, setTheme] = useState(themeFromLocalStorage)

  const isDarkThemeActive = theme === "dark-theme"
  const isLightThemeActive = theme !== "dark-theme"

  const setDarkTheme = () => {
    document.body.classList.remove(["light-theme"])
    document.body.classList.add("changing-theme-fade")
    document.body.classList.add("dark-theme")
    localStorage.setItem("theme", "dark-theme")
    dispatch(updateFormField("logo", config.REACT_APP_LOGO_IMAGE_DARK_THEME))

    setTheme("dark-theme")
    setTimeout(() => {
      document.body.classList.remove("changing-theme-fade")
    }, 1000)
  }

  const setLightTheme = () => {
    document.body.classList.remove(["dark-theme"])
    document.body.classList.add("changing-theme-fade")
    document.body.classList.add("light-theme")
    localStorage.setItem("theme", "light-theme")
    dispatch(updateFormField("logo", config.REACT_APP_LOGO_IMAGE_LIGHT_THEME))

    setTheme("light-theme")
    setTimeout(() => {
      document.body.classList.remove("changing-theme-fade")
    }, 1000)
  }

  const onLightThemeClick = () => {
    if (expand) return
    setDarkTheme()
  }
  const onDarkThemeClick = () => {
    if (expand) return
    setLightTheme()
  }

  const onThemeSwitchButtonClicked = () => {
    if (isDarkThemeActive) {
      setLightTheme()
    } else {
      setDarkTheme()
    }
  }
  const darkThemeSwitchClass = classNames("dark-theme-switch-container noselect-text", {
    expanded: expand,
  })

  const darkThemeSwitchOverlay = classNames("dark-theme-switch-overlay", {
    hide: !expand,
    leftPosition: isLightThemeActive,
    rightPosition: isDarkThemeActive,
    lightShadow: isDarkThemeActive,
  })

  const sunSwitchSide = classNames("sun-switch-side", {
    hide: isDarkThemeActive && !expand,
    shade: isDarkThemeActive,
  })

  const darkSwitchSide = classNames("dark-switch-side", {
    hide: isLightThemeActive && !expand,
    shade: isLightThemeActive,
    marginLeft: expand,
  })

  return (
    <div
      style={containerStyle}
      className={darkThemeSwitchClass}
      onClick={onThemeSwitchButtonClicked}
    >
      <div className={darkThemeSwitchOverlay} />
      <div className={sunSwitchSide} onClick={onLightThemeClick}>
        <SvgIcon icon="sun" className="theme-switch" />
        <span style={{ display: expand ? "inline" : "none" }}>Light</span>
      </div>
      <div className={darkSwitchSide} onClick={onDarkThemeClick}>
        <SvgIcon icon="moon" className="theme-switch" />
        <span style={{ display: expand ? "inline" : "none" }}>Dark</span>
      </div>
    </div>
  )
}

DarkThemeSwitch.propTypes = {
  expand: PropTypes.bool,
  containerStyle: PropTypes.instanceOf(Object),
}

DarkThemeSwitch.defaultProps = {
  expand: false,
  containerStyle: {},
}

export default DarkThemeSwitch
