/* eslint-disable no-use-before-define */
import "./InputGroup.scss"
import "react-quill/dist/quill.snow.css"
import "react-dates/lib/css/_datepicker.css"
import "react-dates/initialize"

import { CardElement } from "@stripe/react-stripe-js"
import classNames from "classnames"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import TextareaAutosize from "react-autosize-textarea/lib"
import { Col, Form, InputGroup as BSInputGroup, Row } from "react-bootstrap"
import { SingleDatePicker } from "react-dates"
import ReactQuill from "react-quill"
import { useDispatch, useSelector } from "react-redux"
import TimezonePicker, { timezones } from "react-timezone"

import { HELP_LINKS } from "../../../constants/help-center"
import {
  clearFormError,
  hideInfoModal,
  showInfoModal,
  updateFormField,
} from "../../../redux/actions"
import HelpCenter from "../../atoms/HelpCenter"
import SvgIcon from "../../atoms/SvgIcon"
import SvgIconRound from "../../atoms/SvgIconRound"
import TimePicker from "../../atoms/TimePicker"

const InputGroup = ({
  accept,
  after,
  append,
  ariaLabel,
  autoComplete,
  autoFocus,
  before,
  bordered,
  children,
  customError,
  customValue,
  description,
  disabled,
  expandable,
  extraInput,
  field,
  gray,
  hideMaxLength,
  errorIconClassName,
  iconClassName,
  iconName,
  inputClassName,
  inputGroupClassName,
  inputLabel,
  inputProps,
  isConnectMessage,
  label,
  labelColor,
  margin,
  maxLength,
  multiplePasswordType,
  noFormError,
  noRedux,
  onChange,
  onEnter,
  onIconClick,
  onKeyUp,
  outerErrorMessage,
  passwordType,
  placeholder,
  prepend,
  preview,
  required,
  rightLabel,
  setCurrentEvent,
  setMultiplePasswordType,
  setPasswordType,
  siblingComponent,
  style,
  transparent,
  type,
  useDebounce,
  useEnterAsDefault,
  variant,
  tooltipText,
  tooltipLeft,
  tooltipTop,
  caret,
  iconWrapperClassName,
}) => {
  const dispatch = useDispatch()
  const { formData, formError } = useSelector(state => ({
    formData: state.forms.formData,
    formError: state.forms.formErrors,
  }))

  const inputValue = useMemo(() => {
    if (type === "file") {
      return ""
    }

    if (type === "timezone") {
      if (typeof formData[field] !== "undefined") {
        return formData[field].name || ""
      }
      return ""
    }

    if (type === "html") {
      if (typeof formData[field] !== "undefined") {
        return formData[field] || ""
      }
      if (customValue !== null) {
        return customValue
      }
      return ""
    }

    if (customValue !== null) {
      if (!customValue && customValue !== 0) {
        return ""
      }
      return customValue
    }
    if (typeof formData[field] !== "undefined") {
      if (!formData[field] && formData[field] !== 0) {
        return ""
      }

      return formData[field]
    }
    return ""
  }, [field, type, formData[field], customValue])

  const [characterCount, setCharacterCount] = useState(0)
  const [tempValue, setTempValue] = useState(inputValue)

  const tagReplacementLength = 10

  useEffect(() => {
    if (inputValue !== tempValue) {
      setTempValue(inputValue)
    }
  }, [inputValue])

  useEffect(() => {
    if (isConnectMessage) {
      const tags = tempValue.match(/{{(.*?)}}/g) || []
      const numberOfTags = tags.length
      let sumOfTagsLengths = 0
      tags.forEach(tag => {
        sumOfTagsLengths += tag.length
      })
      /** Calculations below are necessary in the case of connect message, as each tag is counted as
       *  10 characters regardless of its actual length.
       */
      const numberOfInputCharacters =
        tempValue.length - sumOfTagsLengths + numberOfTags * tagReplacementLength
      setCharacterCount(numberOfInputCharacters)
      dispatch(updateFormField("connectMessageCustomCharacterCount", numberOfInputCharacters))
    }
  }, [tempValue])

  const handleChange = event => {
    if (formError[field] && event && event.target && event.target.id && !noRedux) {
      dispatch(clearFormError(event.target.id))
    }

    if (type === "timezone") {
      const timezoneData = timezones.find(timezone => timezone.name === event)
      dispatch(updateFormField(field, timezoneData))
    } else if (type === "timepicker") {
      dispatch(updateFormField(field, event))
    } else if (type === "datepicker") {
      dispatch(updateFormField(field, event))
    } else if (type === "html") {
      dispatch(updateFormField(field, event))
    } else if (type !== "card") {
      const { id, value, files } = event.target || {}
      let data = value
      if (type === "file") {
        const [file] = files
        data = file
      }

      if (!noRedux) {
        dispatch(updateFormField(id, data))
      }
    }

    if (onChange) {
      onChange(event)
    }
  }

  const dataHandler = useCallback(debounce(handleChange, useDebounce ? 500 : 0), [handleChange])

  useEffect(() => {
    if (["html", "timepicker", "datepicker", "timezone"].includes(type)) {
      dataHandler(tempValue)
    } else {
      const dataObj = { id: field || type, value: tempValue }
      dataHandler({ target: dataObj, currentTarget: dataObj, persist: () => {} })
    }
    return () => {
      dataHandler.cancel()
    }
  }, [tempValue])

  const handleChangeWrapper = e => {
    const newValue = ["html", "timepicker", "datepicker", "timezone"].includes(type)
      ? e
      : e?.target?.value || e?.currentTarget?.value || ""

    setTempValue(newValue)
    if (setCurrentEvent) {
      e.persist()
      setCurrentEvent(e)
    }
  }

  const error = (!noFormError && formError[field]) || customError || ""

  const inputGroupClass = classNames("input-group-wrapper", {
    "input-transparent": transparent,
    "input-gray": gray,
    "input-error": error,
    "input-bordered": bordered,
    "icon-input": !!iconName,
    [variant]: true,
    [inputGroupClassName]: inputGroupClassName,
  })
  const iconClassNames = classNames(iconClassName, "input-icon")
  const errorIconClassNames = classNames(errorIconClassName, "input-icon error-icon")
  const labelClassName = classNames("form-label", { "form-label-small": transparent })
  const lengthClassName = classNames("form-input-maxlength", {
    "input-length-exceeded": isConnectMessage
      ? characterCount >= Number(maxLength)
      : tempValue?.length === Number(maxLength),
  })
  const passwordIconClassName = classNames("password-icon", {
    "password-icon-error": error,
  })

  const handleKeyUp = event => {
    if (
      formError[field] &&
      event &&
      event.target &&
      event.target.id &&
      event.key !== "Enter" &&
      Object.keys(formError).includes([
        "randomAdditionalDelay",
        "dailyEmailLimit",
        "isGreaterThan15Minutes",
      ])
    ) {
      dispatch(clearFormError(event.target.id))
    }

    if (onKeyUp) {
      onKeyUp(event)
    }
  }

  /** Array of symbols that are not allowed as input when the input type is "number". */
  const ignoreSymbols = ["-", "+", "e", "E"]

  const handleKeyDown = event => {
    if (type === "number" && ignoreSymbols.includes(event.key)) {
      event.preventDefault()
    }

    if (event.key === "Enter") {
      if (!useEnterAsDefault) {
        event.preventDefault()
        if (onEnter) {
          onEnter(event)
        }
      }
      /** This return is necessary to continue original event propagation */
      // eslint-disable-next-line no-useless-return
      return
    }
  }

  const handlePaste = event => {
    const pasteContent = (event.clipboardData || window.clipboardData).getData("text")

    if (type === "number" && ignoreSymbols.some(symbol => pasteContent.includes(symbol))) {
      event.preventDefault()
    }
  }

  const getInputType = () => {
    if (type === "card") {
      return CardElement
    }
    if (type === "select") {
      return "select"
    }
    if (type === "timezone") {
      return TimezonePicker
    }
    if (type === "timepicker") {
      return TimePicker
    }
    if (type === "textarea") {
      return TextareaAutosize
    }

    if (type === "html") {
      return ReactQuill
    }
    if (type === "datepicker") {
      return SingleDatePicker
    }
  }

  const renderData = hide => (
    <>
      {before}
      <div className={inputGroupClass} style={{ ...style, margin }} key={field}>
        <Form.Group controlId={field}>
          {(label || rightLabel) && (
            <Row className="input-group-labels">
              {label && (
                <Col>
                  <label className={labelClassName} style={{ color: labelColor }}>
                    {label}
                  </label>
                  {inputProps.showHelpCenter && (
                    <span className="ml-1 helper-tooltip">
                      <HelpCenter
                        link={HELP_LINKS.WORKING_HOURS_SETTINGS_OPTION}
                        hover={inputProps.hover}
                      />
                    </span>
                  )}
                </Col>
              )}
              {rightLabel && <Col>{rightLabel}</Col>}
            </Row>
          )}
          <div className="position-relative">
            <BSInputGroup>
              {prepend && (
                <BSInputGroup.Prepend>
                  <BSInputGroup.Text id="basic-addon1">{prepend}</BSInputGroup.Text>
                </BSInputGroup.Prepend>
              )}

              <Form.Control
                aria-label={placeholder || label || ariaLabel}
                key={field}
                as={getInputType()}
                className={inputClassName}
                type={type}
                name={field}
                placeholder={placeholder}
                value={tempValue}
                onChange={handleChangeWrapper}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                required={required}
                maxLength={isConnectMessage ? undefined : maxLength}
                autoComplete={autoComplete}
                accept={accept}
                disabled={disabled}
                autoFocus={autoFocus}
                onPaste={handlePaste}
                {...inputProps}
              >
                {children}
              </Form.Control>

              {append && (
                <BSInputGroup.Append>
                  <BSInputGroup.Text id="inputGroupPrepend">{append}</BSInputGroup.Text>
                </BSInputGroup.Append>
              )}
              {siblingComponent}
              {inputLabel && (
                <label className="custom-file-label" htmlFor={field}>
                  {inputLabel}
                </label>
              )}

              {preview && preview}
              {extraInput && extraInput}

              {!!multiplePasswordType && Object.keys(multiplePasswordType).includes(field) && (
                <div className={passwordIconClassName}>
                  <SvgIcon
                    icon={
                      multiplePasswordType[field] !== "password" ? "show-password" : "hide-password"
                    }
                    onClick={() => {
                      setMultiplePasswordType({
                        ...multiplePasswordType,
                        [field]: multiplePasswordType[field] === "password" ? "text" : "password",
                      })
                    }}
                  />
                </div>
              )}

              {passwordType === "password" && (
                <div className={passwordIconClassName}>
                  <SvgIcon
                    icon="hide-password"
                    onClick={() => {
                      setPasswordType("text")
                    }}
                  />
                </div>
              )}

              {passwordType === "text" && (
                <div className={passwordIconClassName}>
                  <SvgIcon
                    icon="show-password"
                    onClick={() => {
                      setPasswordType("password")
                    }}
                  />
                </div>
              )}

              {expandable && (
                <SvgIcon
                  icon="input-expand"
                  onClick={() => {
                    onExpand(hide)
                  }}
                  className="expand-icon-email"
                />
              )}
            </BSInputGroup>
            {error ? (
              <span className={errorIconClassNames}>!</span>
            ) : (
              !!iconName && (
                <span onClick={onIconClick} className={iconWrapperClassName}>
                  <SvgIcon
                    style={{ zIndex: 1 }}
                    icon={iconName}
                    className={iconClassNames}
                    tooltipText={tooltipText}
                    tooltipLeft={tooltipLeft}
                    tooltipTop={tooltipTop}
                    caret={caret}
                  />
                </span>
              )
            )}
          </div>
          {outerErrorMessage && <div className="outer-error-container">{outerErrorMessage}</div>}
          {description && <span className="form-description">{description}</span>}
          {!hideMaxLength && maxLength && (
            <span className={lengthClassName}>
              Characters {isConnectMessage ? characterCount : tempValue.length} / {maxLength}
            </span>
          )}
          {error && (
            <span className="error-message">
              <SvgIconRound icon="error-icon" className="error-message-icon" />
              {error}
            </span>
          )}
        </Form.Group>
      </div>
      {after}
    </>
  )

  const onExpand = hide => {
    if (hide) {
      dispatch(hideInfoModal())
    } else {
      dispatch(
        showInfoModal(
          "basic",
          "Email",
          renderData(true),
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          "expand-modal",
        ),
      )
    }
  }

  return renderData()
}

InputGroup.propTypes = {
  accept: PropTypes.string,
  append: PropTypes.string,
  ariaLabel: PropTypes.string,
  autoComplete: PropTypes.oneOf(["on", "off"]),
  autoFocus: PropTypes.bool,
  bordered: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  field: PropTypes.string,
  gray: PropTypes.bool,
  iconName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  inputLabel: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object),
  isConnectMessage: PropTypes.bool,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noFormError: PropTypes.bool,
  noRedux: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  transparent: PropTypes.bool,
  placeholder: PropTypes.string,
  prepend: PropTypes.oneOf([PropTypes.instanceOf(Object), PropTypes.string]),
  setCurrentEvent: PropTypes.func,
  siblingComponent: PropTypes.element,
  style: PropTypes.instanceOf(Object),
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "file",
    "card",
    "select",
    "timezone",
    "timepicker",
    "textarea",
    "datepicker",
    "password",
    "html",
  ]),
  useDebounce: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "backgrounded"]),
  customValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  iconWrapperClassName: PropTypes.string,
}

InputGroup.defaultProps = {
  accept: "",
  after: null,
  append: "",
  ariaLabel: "",
  autoComplete: "off",
  autoFocus: false,
  before: null,
  bordered: false,
  children: null,
  customValue: null,
  disabled: false,
  field: undefined,
  gray: false,
  iconName: "",
  inputGroupClassName: "",
  inputLabel: undefined,
  inputProps: {},
  isConnectMessage: false,
  label: "",
  labelColor: undefined,
  margin: "0.5rem 0 1.2rem",
  noFormError: false,
  noRedux: false,
  onChange: undefined,
  onKeyUp: undefined,
  placeholder: "",
  prepend: null,
  setCurrentEvent: undefined,
  siblingComponent: undefined,
  style: {},
  transparent: false,
  type: "text",
  useDebounce: false,
  variant: "default",
  errorIconClassName: "",
  iconWrapperClassName: "",
}

export default React.memo(InputGroup)
