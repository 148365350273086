import config from "../../../utils/config"
import mainIcons from "./mainIcons"
import whitelabelIcons from "./whitelabelIcons"

const getIcon = (className, style, icon) => {
  const whitelabelId = config.REACT_APP_PRODUCT_ID

  let svgIcon
  let svgIconFunc

  if (whitelabelIcons[whitelabelId]) {
    svgIconFunc = whitelabelIcons[whitelabelId][icon]
    if (svgIconFunc) {
      svgIcon = svgIconFunc(className, style)
    }
  }

  if (!svgIcon) {
    svgIconFunc = mainIcons[icon]
    if (svgIconFunc) {
      svgIcon = svgIconFunc(className, style)
    }
  }

  return svgIcon
}

export { getIcon }
